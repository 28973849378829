import { AfterViewInit, Component, computed, ElementRef, inject, OnDestroy, viewChild } from "@angular/core"
import { BreadcrumbComponent } from "../breadcrumb.component"
import { CategoriesComponent } from "../products/categories.component"
import { SearchComponent } from "../search.component"
import { SupportComponent } from "../support.component"
import { BrandsComponent } from "../brands.component"
import { UtilBreakpointsService } from "util/breakpoints"
import { Breakpoints } from "@angular/cdk/layout"
import { NgOptimizedImage } from "@angular/common"
import { RouterLink } from "@angular/router"
import { fromEvent, Subscription } from "rxjs"
import { AppService } from "../../app.service"
import { isElementInViewport } from "util/scroll-to"
import { DeliveryComponent } from "../delivery.component"
import { SchoolComponent } from "./school.component"
import { EventsComponent } from "./events.component"

@Component({
  imports: [
    BreadcrumbComponent,
    CategoriesComponent,
    SearchComponent,
    SupportComponent,
    BrandsComponent,
    NgOptimizedImage,
    RouterLink,
    DeliveryComponent,
    SchoolComponent,
    EventsComponent,
  ],
  standalone: true,
  template: `
    <div class="column gap-10">

      <div class="row-center gap-16 pt-10">
        <div>
          <img
            style="max-width: 300px"
            alt=""
            ngSrc="assets/logo.png"
            height="300"
            width="300"
            priority=""
          />
        </div>
        <div
          class="column-center"
          style="max-width: 65ch"
        >
          <h1 class="text-3xl font-bold">
            Find the supplies you need!
          </h1>
          <div class="row">
            <button
              class="btn btn-primary text-xl"
              routerLink="/products"
            >
              START HERE
            </button>
            or
            <a
              #CallUs
              class="btn btn-primary btn-sm"
              href="tel:17634275850"
            >
              Call us: 763-427-5850
            </a>
          </div>

          <div class="center pt-12 text-xl">
            Minnesota's best and biggest selection of
            <br>
            tools and equipment for the Professional Farrier
            <br>
            Established in 1973
          </div>

        </div>
      </div>

      <e2e-delivery/>

      <e2e-support/>

      <e2e-brands/>

      <h2 class="center text-2xl font-bold">
        Product Categories
      </h2>

      <e2e-categories/>

      <h2 class="center text-2xl font-bold">
        Product Search
      </h2>

      <div class="row-center">
        <e2e-search [searchInput]="searchTerm()"/>
      </div>

    </div>
  `,
  selector: "e2e-home",
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  private breakpointService = inject(UtilBreakpointsService)
  private appService = inject(AppService)

  searchTerm = this.appService.searchTerm

  callUsElementRef = viewChild.required<ElementRef<HTMLElement>>("CallUs")
  callUsElement = computed(() => this.callUsElementRef().nativeElement)

  sub: Subscription | undefined = undefined

  minWidth = computed(() => {
    switch (this.breakpointService.currentBreakpoint()) {
      case Breakpoints.XSmall:
      case Breakpoints.Small:
        return undefined
      case Breakpoints.Medium:
      case Breakpoints.Large:
      case Breakpoints.XLarge:
      default:
        return 600
    }
  })

  ngAfterViewInit() {
    this.sub = fromEvent(window, "scroll")
      .subscribe(() => {
        this.appService.callUsElementIsInViewport.set(isElementInViewport(this.callUsElement()))
      })
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe()
  }

}
