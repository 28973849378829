import { IdTokenResult } from "@angular/fire/auth/firebase"
import { User } from "@angular/fire/auth"

export interface UserRecord extends User {
  customClaims?: {
    roles: Role[]
  },
}

export enum Role {
  ADMIN = "ADMIN",
  BLOCKED = "BLOCKED",
  USER_ADMIN = "USER_ADMIN",

  // CONTRIBUTOR = "CONTRIBUTOR",
  // EDITOR = "EDITOR",
  // MODERATOR = "MODERATOR",
}

export type HasRole = { [K in Role]: boolean }

export interface IdTokenResultWithClaims extends IdTokenResult {
  claims: {
    email?: string
    emailVerified?: boolean
    name?: string
    picture?: string
    userId?: string
    roles?: Role[]
  }
}
