import { AfterViewInit, Directive, ElementRef, EventEmitter, inject, input, Output } from "@angular/core"
import { Sortable } from "@shopify/draggable"
import { SortableStopEvent } from "@shopify/draggable/lib/draggable.bundle.legacy"

@Directive({
  standalone: true,
  selector: "[libSortable]"
})
export class SortableDirective implements AfterViewInit {
  private elementRef = inject(ElementRef)

  data = input.required<string[]>()
  dataArrayKey = input.required<string>()
  draggableClassName = input.required<string>()

  @Output() stop = new EventEmitter()

  sortable: Sortable | undefined

  ngAfterViewInit() {
    this.sortable = new Sortable(this.elementRef.nativeElement, {
      draggable: ".draggable-" + this.draggableClassName,
      handle: ".handle-" + this.draggableClassName
    })

    // this.sortable.on('sortable:start', e => this.start.emit(e));
    // this.sortable.on('sortable:sort', e => this.sort.emit(e));
    // this.sortable.on('sortable:sorted', e => this.sorted.emit(e));

    this.sortable.on("sortable:stop", sortableStopEvent =>
      this.handleStop(sortableStopEvent)
    )
  }

  handleStop(sortableStopEvent: SortableStopEvent) {
    const { newIndex, oldIndex } = sortableStopEvent
    const next: string[] = this.data()
    const moved = next.splice(oldIndex, 1)
    next.splice(newIndex, 0, moved[0])

    this.stop.emit({ [this.dataArrayKey()]: next })
  }
}
