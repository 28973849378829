import { inject, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"

export type Video = {
  thumbnailUrl?: string
  title?: string
  url?: string
  id?: string
  type: VideoType
  html?: string
  thumbnail_url_with_play_button?: string
}

export enum VideoType {
  VIMEO_ID = "VIMEO_ID",
  EXTERNAL_LINK = "EXTERNAL_LINK"
}

export type VimeoOembedJSON = {
  type: "video"
  version: "1.0"
  provider_name: "Vimeo"
  provider_url: "https://vimeo.com/"
  title: string
  author_name: string
  author_url: string
  is_plus: string
  account_type: string
  html: string
  width: number
  height: number
  duration: number
  description: string
  thumbnail_url: string
  thumbnail_width: number
  thumbnail_height: number
  thumbnail_url_with_play_button: string
  upload_date: string
  video_id: number
  uri: string
}

@Injectable({
  providedIn: "root"
})
export class VideoService {
  private httpClient = inject(HttpClient)

/*
  getVimeoData(vimeoId: string): void {
    this.httpClient.get("https://vimeo.com/api/oembed.json?url=http://vimeo.com/" + vimeoId, { responseType: "json" })
      .pipe(
        take(1),
        timeout(5000)
      )
      .subscribe((response: VimeoOembedJSON) => {
        this.globalState.set("vimeoOembedJSON", () => response)
      })


  }
*/

/*
  async fetchVimeoData(id: string): Promise<VimeoOembedJSON> {
    try {
      return await firstValueFrom(this.httpClient.get("https://vimeo.com/api/oembed.json?url=http://vimeo.com/" + id, { responseType: "json" })) as VimeoOembedJSON
    } catch (e) {
      console.log(e)
    }

    // return
  }
*/

}

