import { Component, computed, effect, inject, signal, untracked } from "@angular/core"
import { DataAccessFirebaseAuthUserManagerEditComponent } from "./data-access-firebase-auth-user-manager-edit.component"
import { UiInheritDirective } from "ui/inherit"
import { DataAccessFirebaseAuthService } from "./data-access-firebase-auth.service"
import { DataAccessFirebaseAuthProfileService } from "./data-access-firebase-auth-profile.service"

@Component({
  imports: [
    DataAccessFirebaseAuthUserManagerEditComponent,
    UiInheritDirective,
  ],
  standalone: true,
  template: `
    <div
      class="column"
      style="width: fit-content; padding: 20px"
    >
      @if (!hasUserAdminPermission()) {
        Access Denied
      }
      @if (users().length) {
        @for (user of users(); track user.userId; let index = $index) {
          <div
            tabindex="0"
            class="collapse bg-base-200"
            (focus)="activateIndex(index)"
          >
            <input
              type="checkbox"
              (change)="activateIndex(index)"
            >
            <div class="collapse-title">
              {{ user.header }}
            </div>
            <div class="collapse-content">
              @defer (when index === userIndex()) {
                <lib-user-manager-edit [userId]="user.userId"/>
              }
            </div>
          </div>
        }
      }
    </div>
  `,
  selector: "lib-user-manager",
})
export class DataAccessFirebaseAuthUserManagerComponent {
  private authDataAccessService = inject(DataAccessFirebaseAuthService)
  private profileDataAccessService = inject(DataAccessFirebaseAuthProfileService)

  hasUserAdminPermission = this.authDataAccessService.hasUserAdminPermission
  userIndex = signal(-1)

  /**
   * TODO: convert to using profile which is available for all users
   */
  users = computed(() =>
    [...this.profileDataAccessService.userProfiles_map().values()].map((userProfile) => ({
      ...userProfile,
      header: userProfile.userName
        ? userProfile.userName + " (" + userProfile.userEmail + ") "
        : userProfile.userEmail + " ",
    })),
  )

  /*
  users = computed(() => this.usersService.userRecords()
    .map(userRecord => ({
      ...userRecord,
      header: userRecord.displayName
        ? userRecord.displayName + " (" + userRecord.email + ") " + userRecord.uid
        : userRecord.email + " " + userRecord.uid
    }))
  )
*/

  constructor() {
    effect(() => {
      if (this.hasUserAdminPermission()) {
        untracked(() => {
          this.profileDataAccessService.subscribeToAllUserAccounts()
        })
      }
    })
  }

  activateIndex(index: number) {
    this.userIndex.set(index)
  }

}
