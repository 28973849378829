import { Component, input, output } from "@angular/core"
import { UiInheritDirective } from "ui/inherit"
import { SliderImageComponent } from "./slider-image.component"
import { GroupImage } from "./groups/groups.service"

@Component({
  imports: [
    UiInheritDirective,
    SliderImageComponent,
  ],
  standalone: true,
  template: `
    <div style="width: 50%; height: 100%; position: relative">
      @for (imageObject of imageObjects(); track imageObject.src; let index = $index) {
        <e2e-slider-image
          [index]="index"
          [sliderIndex]="sliderIndex()"
          [length]="imageObjects().length"
          [imageObject]="imageObject"
          (changeImage)="changeImage.emit($event)"
          inherit
        />
      }
    </div>
  `,
  selector: "e2e-slider",
})
export class SliderComponent {
  imageObjects = input.required<GroupImage[]>()
  sliderIndex = input.required<number>()

  changeImage = output<GroupImage>()

  showPrevious(event: any) {
    if (this.sliderIndex() > 0) {
      this.changeImage.emit(this.imageObjects()[this.sliderIndex() - 1])
    }
  }
  showNext(event: any) {
    if (this.sliderIndex() < this.imageObjects().length - 1) {
      this.changeImage.emit(this.imageObjects()[this.sliderIndex() + 1])
    }
  }

}
