import { Component, input } from "@angular/core"
import { CurrencyPipe } from "@angular/common"
import { Item } from "../import/content.service"

@Component({
  imports: [
    CurrencyPipe,
  ],
  standalone: true,
  template: `
    @if (itemInput(); as item) {
      <div 
        [class]="selected() ? 'btn btn-success' : 'btn'"
      >
        @if (item.title) {
          <div style="white-space: nowrap">
            {{ item.title }}
            @if (item.retail && item.unit) {
              {{ item.retail | currency }}
              {{ item.unit }}
            }
          </div>
        }
      </div>
    }
  `,
  selector: "e2e-item",
})
export class ItemComponent {
  itemInput = input.required<Item>({ alias: "item" })
  selected = input(false)
}
