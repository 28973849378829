import { Injectable } from "@angular/core"
import {
  AuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
} from "@angular/fire/auth"

export enum ProviderType {
  APPLE = 'apple.com',
  FACEBOOK = "facebook.com",
  GITHUB = "github.com",
  GOOGLE = "google.com",
  MICROSOFT = "microsoft.com",
  TWITTER = "twitter.com",
  PASSWORD = "password"
}

export type Provider = {
  label: string
  signIn: "withPopup" | "withPassword"
  type: ProviderType
}

@Injectable({
  providedIn: "root",
})
export class UtilAuthProviderService {
  create(providerType: string): AuthProvider | undefined {
    const providerMapping: Record<string, () => AuthProvider> = {
      [ProviderType.APPLE]: () => new OAuthProvider(ProviderType.APPLE),
      [ProviderType.FACEBOOK]: () => new FacebookAuthProvider(),
      [ProviderType.GITHUB]: () => new GithubAuthProvider(),
      [ProviderType.GOOGLE]: () => new GoogleAuthProvider(),
      [ProviderType.MICROSOFT]: () => new OAuthProvider(ProviderType.MICROSOFT),
      [ProviderType.TWITTER]: () => new TwitterAuthProvider(),
    }
    const providerFunction = providerMapping[providerType]
    return providerFunction ? providerFunction() : undefined
  }

  provider(providerType: ProviderType) {
    return this.providers_map().get(providerType)
  }

  providers_map() {
    return new Map(this.providers().map(provider => [provider.type, provider]))
  }

  providers(): Provider[] {
    return [
      {
        label: "Apple",
        signIn: "withPopup",
        type: ProviderType.APPLE,
      },
      {
        label: "Facebook",
        signIn: "withPopup",
        type: ProviderType.FACEBOOK,
      },
      {
        label: "GitHub",
        signIn: "withPopup",
        type: ProviderType.GITHUB,
      },
      {
        label: "Google",
        signIn: "withPopup",
        type: ProviderType.GOOGLE,
      },
      {
        label: "Microsoft",
        signIn: "withPopup",
        type: ProviderType.MICROSOFT,
      },
      {
        label: "Twitter",
        signIn: "withPopup",
        type: ProviderType.TWITTER,
      },
      {
        label: "Email/Password",
        signIn: "withPassword",
        type: ProviderType.PASSWORD,
      },
    ]
  }
}
