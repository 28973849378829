import { Directive, ElementRef, inject, OnInit, RendererFactory2 } from "@angular/core"

@Directive({
  selector: "[libInherit], [inherit], lib-ui-checkbox, lib-ui-number, lib-ui-radio, lib-ui-textarea, lib-ui-alert, lib-ui-collapse, lib-ui-dialog, lib-ui-drawer, lib-ui-toast, lib-badge, lib-bookmark, lib-icon, lib-next-section-button, lib-login, lib-sign-in, lib-sign-out, lib-sign-in-provider-button, lib-sign-in-with-password, lib-theme-dark-mode-switcher, lib-user-manager, lib-user-manager-edi",
  standalone: true,
})
export class UiInheritDirective implements OnInit {
  private elementRef = inject(ElementRef)
  private rendererFactory = inject(RendererFactory2)

  ngOnInit() {
    const renderer = this.rendererFactory.createRenderer(null, null)
    renderer.setStyle(this.elementRef.nativeElement, "display", "contents")
  }

}
