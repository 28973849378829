import { ApplicationConfig, importProvidersFrom, provideExperimentalZonelessChangeDetection } from "@angular/core"
import { provideRouter, withViewTransitions } from "@angular/router"
import { appRoutes } from "./app.routes"
import { environment } from "../environments/environment"
import { initializeApp, provideFirebaseApp } from "@angular/fire/app"
import { getFirestore, provideFirestore } from "@angular/fire/firestore"
import { getAuth, provideAuth } from "@angular/fire/auth"
import { provideAnimations } from "@angular/platform-browser/animations"
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import { provideNgxStripe } from "ngx-stripe"
import { getStorage, provideStorage } from "@angular/fire/storage"
import { provideImageKitLoader } from "@angular/common"
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics"
import { UtilEnvironmentService } from "util/environment"
import { AuthTokenHttpInterceptorProvider } from "data-access/firebase-auth"

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    // provideFirestore(() => initializeFirestore(
    //   getApp(),
    //   { localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }) }),
    // ),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    // provideRemoteConfig(() => getRemoteConfig()),
    provideAnimations(),
    importProvidersFrom(
      // BrowserAnimationsModule,
      // BrowserModule,
      // HammerModule,
      // AuthGuard,
    ),
    provideExperimentalZonelessChangeDetection(),
    // provideZoneChangeDetection({ eventCoalescing: true }),
    // provideClientHydration(withEventReplay()),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxStripe(environment.stripeKey),
    provideRouter(
      appRoutes,
      // withEnabledBlockingInitialNavigation(), // for SSR
      // withViewTransitions(),
    ),
    AuthTokenHttpInterceptorProvider,
    // HttpErrorInterceptorProvider,
    // { provide: ConfirmationService },
    // { provide: MessageService },
    provideImageKitLoader(environment.imagekit_urlEndpoint),
    {
      ...UtilEnvironmentService.environmentProvider,
      useFactory: (configService: UtilEnvironmentService) => () => configService.setEnvironment({
        production: environment.production,
        storageBucket: environment.firebaseConfig.storageBucket,
        baseUrl: environment.baseUrl,
      }),
    },
  ],
}
