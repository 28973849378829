import { computed, inject, Injectable, signal } from "@angular/core"
import { collection, doc, Firestore, setDoc, writeBatch, Timestamp } from "@angular/fire/firestore"
import { read, utils } from "xlsx"
import { idFromText } from "@shared"
import { ref, Storage, uploadBytesResumable } from "@angular/fire/storage"
import { compressToUTF16, decompressFromUTF16 } from "lz-string"
import { MenuItem } from "./firestore-read.service"
import { Field } from "../../fields/fields.interface"
import { ImportDataRaw, ImportedGroupRow, ImportedItemRow } from "./import.service"

export enum ContentType {
  PAGE = "PAGE",
  ROOT_GROUP = "ROOT_GROUP",
  GROUP = "GROUP",
  PRODUCT = "PRODUCT",
  ITEM = "ITEM",
  MENU = "MENU",
}

export interface Item {
  docId: string
  description: string
  images: string[]
  manufacturer: string
  retail: number
  sku: string // unique id
  title: string
  unit: string
}

export interface Group {
  docId: string
  groupRefs: string[] // by sku
  itemRefs: string[] // by sku
  description: string
  groups: string[]
  images: string[]
  sku: string // unique id
  title: string
}

export interface Groups {
  [sku: string]: Group
}

export interface Items {
  [sku: string]: Item
}

export interface ContentDocs {
  [contentDocId: string]: ContentDoc
}

export interface ContentDoc {
  content: Groups | Items | MenuItem[]
  id: string // docId
  type: ContentType
}


export interface Content {
  id: string // contentId
  fieldGroups: {
    fields: Field[]
  }[]
  status: Status
  type: ContentType
  // version: Version
}


export enum Status {
  PUBLISHED = "P",
  UNPUBLISHED = "U",
  DELETED = "D",
}

export interface SetDataOptions {
  cache: boolean
}

export interface GroupImage {
  src: string
  thumbnailImageSrc: string
  alt: string
  title: string
}

export interface GroupView {
  groupRefs: string[] // by sku
  itemRefs: string[] // by sku
  description: string
  groups: string[]
  sku: string // unique id
  title: string
  children: GroupView[]
  images: GroupImage[]
  menuItem: MenuItem
}

@Injectable({
  providedIn: "root",
})
export class ContentService {
  private firestore = inject(Firestore)
  private storage = inject(Storage)

  previousRow: ImportedItemRow | undefined = undefined

  randomFilename = signal("")
  version = signal("")

  menuItems_array = signal<MenuItem[]>([])
  items_keyed = signal<Items>({})
  groups_keyed = signal<Groups>({})

  items_map = computed(() => new Map(Object.entries(this.items_keyed())))
  groups_map = computed(() => new Map(Object.entries(this.groups_keyed())))

  processSpreadsheet(spreadsheetFile: Blob) {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (!(arrayBuffer instanceof ArrayBuffer)) {
        return console.error("FileReader result is not an ArrayBuffer")
      }
      const data = new Uint8Array(arrayBuffer)
      const arr: string[] = []
      data.map((charCode: number) => {
        arr.push(String.fromCharCode(charCode))
        return 0
      })
      const binaryString = arr.join("")
      const workbook = read(binaryString, { type: "binary" })
      const sheetNames = workbook.SheetNames
      const worksheet = workbook.Sheets[sheetNames[0]]
      const excelJsonData: ImportDataRaw[] = utils.sheet_to_json(worksheet, {
        blankrows: false,
        raw: true,
        defval: "",
        skipHidden: true,
        rawNumbers: true,
      })

      /**
       * TODO: test data structure, maybe with zod???
       */
      this.processImportedData(excelJsonData)
    }

    fileReader.readAsArrayBuffer(spreadsheetFile)
  }

  getLocalStorageRawData() {
    const version = localStorage.getItem("dv")
    if (version) {
      this.version.set(version)
    }

    try {
      const dataString = localStorage.getItem("da")
      if (dataString) {
        this.menuItems_array.set(JSON.parse(decompressFromUTF16(dataString)))
      }
    } catch (error) {
      console.error("Parsing error:", error)
    }
    try {
      const dataString = localStorage.getItem("db")
      if (dataString) {
        this.items_keyed.set(JSON.parse(decompressFromUTF16(dataString)))
      }
    } catch (error) {
      console.error("Parsing error:", error)
    }
    try {
      const dataString = localStorage.getItem("dc")
      if (dataString) {
        this.groups_keyed.set(JSON.parse(decompressFromUTF16(dataString)))
      }
    } catch (error) {
      console.error("Parsing error:", error)
    }
  }

  setLocalStorageRawData(dataLabel: string, dataString: string) {
    localStorage.setItem(dataLabel, dataString)
    // const rawDataSize_string = (dataString.length / (1024 * 1024)).toFixed(3) + " MB"
    // localStorage.setItem(dataLabel + "_size", rawDataSize_string)
  }

  storeJsonFileData(
    jsonFileData: {
      menuItems_array: MenuItem[],
      items_keyed: Items,
      groups_keyed: Groups
    },
  ) {
    /**
     * upload jsonFile to storage bucket
     */
    const jsonFileRef = "imports/" + this.randomFilename() + ".json"
    const jsonFileRef_lz = "imports/" + this.randomFilename() + ".json.lz"
    const jsonFile = new Blob(
      [JSON.stringify(jsonFileData, null, 2)],
      { type: "application/json" },
    )
    const jsonFile_lz = new Blob(
      [compressToUTF16(JSON.stringify(jsonFileData, null, 2))],
      { type: "application/json" },
    )
    const storageRef = ref(this.storage, jsonFileRef)
    const storageRef_lz = ref(this.storage, jsonFileRef_lz)
    uploadBytesResumable(storageRef, jsonFile, { cacheControl: "public, max-age=31104000" })
    uploadBytesResumable(storageRef_lz, jsonFile_lz, { cacheControl: "public, max-age=31104000" })
      .then((snap) => {
        if (snap && snap.state === "success") {
          setDoc(
            doc(
              collection(this.firestore, "content"),
              "content",
            ),
            {
              jsonFileRef: jsonFileRef_lz,
              jsonFileRef_seconds: Timestamp.now().seconds,
              jsonFileRef_timestamp: Timestamp.now(),
            },
            { merge: true },
          )
            .catch((error) => {
              console.log(error)
              alert("failed to save storage filename, please try again")
            })
        }
      })
  }

  processJsonFileData(jsonFile: Blob, dataVersion: string) {
    /**
     * read jsonFile from storage bucket
     */
      // TODO: retrieve jsonFile from storage

    const fileReader = new FileReader()
    fileReader.onload = () => {
      const result = fileReader.result
      if (typeof result !== "string") {
        return console.error("FileReader result is not an ArrayBuffer")
      }
      const { menuItems_array, items_keyed, groups_keyed } = JSON.parse(decompressFromUTF16(result))
      // TODO: verify jsonData
      this.menuItems_array.set(menuItems_array)
      this.items_keyed.set(items_keyed)
      this.groups_keyed.set(groups_keyed)
      this.setLocalStorageRawData("da", compressToUTF16(JSON.stringify(menuItems_array)))
      this.setLocalStorageRawData("db", compressToUTF16(JSON.stringify(items_keyed)))
      this.setLocalStorageRawData("dc", compressToUTF16(JSON.stringify(groups_keyed)))
      this.setLocalStorageRawData("dv", dataVersion)

      // console.log(menuItems_array)
      // console.log(items_keyed)
      // console.log(groups_keyed)
    }
    fileReader.readAsText(jsonFile)
  }

  processImportedData(data: ImportDataRaw[]) {
    const previousRow = this.previousRow

    // process groups and items separately...
    // maybe process all rows, then separate into groups and items
    // const importedRow: ImportedGroupRow | ImportedItemRow = data.map...

    const importedRows = data
      // .slice(0, 3300)
      .map(row => {
        const importedRow: ImportedGroupRow | ImportedItemRow = {
          docId: "",
          description: row.description.toString(),
          groupedParents: this.getParentGroups([row.group1, row.group2, row.group3, row.group4]),
          images: row.images
            .split("*")
            .map(group => group
              .replace(/^\s+/, "") // trim leading whitespace(s)
              .replace(/\s+$/, "") // trim trailing whitespace(s)
              .toString(),
            )
            .filter(image => !!image),
          manufacturer: row.manufacturer.toString() || previousRow?.manufacturer || "",
          retail: row.retail,
          sku: row.sku.toString(),
          title: row.title.toString(),
          unit: row.unit.toString() || previousRow?.unit || "",
        }

        // if (!importedRow.groupedParents.length)
        //   importedRow.groupedParents = previousRow?.groupedParents || []
        this.previousRow = importedRow

        return importedRow
      })
      .filter(importedRow => !!importedRow.sku)

    const importedGroupRows: ImportedGroupRow[] = importedRows
      .filter(importedRow => !importedRow.retail && importedRow.groupedParents.length === 1)
      .map(importedRow => {
        const importedGroupRow: ImportedGroupRow = {
          docId: "",
          description: importedRow.description,
          groupRefs: [],
          images: importedRow.images,
          itemRefs: [],
          groups: importedRow.groupedParents[0],
          sku: importedRow.sku,
          title: importedRow.groupedParents[0].slice(-1)[0],
        }
        return importedGroupRow
      })

    const importedItemRows: ImportedItemRow[] = importedRows
      .filter(importedRow => !!importedRow.retail && importedRow.groupedParents.length)

    console.log("importedRows", importedRows.length)
    console.log("importedRows", importedRows)
    console.log("importedGroupRows", importedGroupRows.length)
    console.log("importedGroupRows", importedGroupRows)
    console.log("importedItemRows", importedItemRows.length)
    console.log("importedItemRows", importedItemRows)


    this.buildGroups(importedGroupRows, importedItemRows)
  }

  buildGroups(importedGroupRows: ImportedGroupRow[], importedItemRows: ImportedItemRow[]) {
    /**
     * build groups object
     * key by "sku"
     * add groupRefs to groups
     * add itemRefs to groups
     */
    const groups_keyed: Groups = {}

    importedGroupRows
      .forEach(group => {
        // add group to groups if it does not exist yet
        // this ensures every group will get added to groups
        if (!groups_keyed[group.sku]) groups_keyed[group.sku] = { ...group }

        const parentGroupString = group.groups.slice(0, -1).join(" ")
        const parentGroupIndex = importedGroupRows
          .findIndex(groupRow => groupRow.groups.join(" ") === parentGroupString)

        if (parentGroupIndex !== -1) {
          const parentGroup = importedGroupRows[parentGroupIndex]
          // add parentGroup to groups if it does not exist yet
          if (!groups_keyed[parentGroup.sku]) groups_keyed[parentGroup.sku] = { ...parentGroup }
          // add sku to parent group's groupRef
          groups_keyed[parentGroup.sku].groupRefs.push(group.sku)
        }
      })

    /**
     * add itemRefs to groups
     * each item has a groupedParents array of group arrays
     */
    const importErrors: string[][][] = []
    importedItemRows
      .forEach(itemRow => {
        itemRow.groupedParents
          .forEach(group => {

            const parentGroupString = group.join(" ")
            const parentGroupIndex = importedGroupRows
              .findIndex(groupRow => groupRow.groups.join(" ") === parentGroupString)
            if (parentGroupIndex === -1) {
              importErrors.push(itemRow.groupedParents)
            }
            if (parentGroupIndex !== -1) {
              const parentGroup = importedGroupRows[parentGroupIndex]
              if (!groups_keyed[parentGroup.sku]) groups_keyed[parentGroup.sku] = { ...parentGroup }
              // add sku to the parent group's itemRef
              groups_keyed[parentGroup.sku].itemRefs.push(itemRow.sku)
            }
          })
      })

    console.error("importErrors", importErrors.length)
    console.error("importErrors", importErrors)

    if (importErrors.length) {
      alert(importErrors.length + " errors. Review errors in console")
    }

    // console.log("groups", Object.keys(groups_keyed).length)
    // console.log("groups", groups)

    this.buildItems(importedItemRows, groups_keyed)
  }

  buildItems(importedItemRows: ImportedItemRow[], groups_keyed: Groups) {
    // const importedItemRows = importedRows
    //   .filter(row => row.parentGroups.length && row.sku && row.retail)

    /**
     *  key by "sku"
     */
    const items_keyed = importedItemRows
      .reduce((acc: Items, item) => ({
        ...acc,
        [item.sku]: {
          docId: "",
          description: item.description,
          images: item.images,
          manufacturer: item.manufacturer,
          retail: item.retail,
          sku: item.sku,
          title: item.title,
          unit: item.unit,
        },
      }), {}) as Items

    console.log("items", Object.keys(items_keyed).length)
    // console.log("items", items)

    this.buildMenuItems(groups_keyed, items_keyed)
  }

  buildMenuItems(groups_keyed: Groups, items_keyed: Items) {
    const menuItems_array = Object.values(groups_keyed)
      .filter(group => group.groups.length === 1) // rootGroups
      .map(group => {
        const menuItem: MenuItem = {
          label: group.title,
          routerLink: "/products/" + idFromText(group.groups[0]),
        }
        return menuItem
      }) as MenuItem[]

    // console.log("menuItems", menuItems_array.length)
    // console.log("menuItems", menuItems_array)

    // this.menuItems_array.set(menuItems_array)
    // this.groups_keyed.set(groups_keyed)
    // this.items_keyed.set(items_keyed)

    // this.setLocalStorageRawData("menuItems_array", JSON.stringify(menuItems_array))
    // this.setLocalStorageRawData("groups_keyed", JSON.stringify(groups_keyed))
    // this.setLocalStorageRawData("items_keyed", JSON.stringify(items_keyed))

    this.storeJsonFileData({ menuItems_array, groups_keyed, items_keyed })

  }

  getParentGroups(importedGroups: string[]): string[][] {
    const rawGroups: string[][] = []

    /**
     * split comma separated
     * remove empty groups
     *
     * result will convert each cell, (cell-1 | cell-2 | cell-3 | cell-4), into nested arrays
     * example 1:
     * ("group1-item1, group1-item2" | "group2-item1" | empty | empty)
     * becomes
     * [
     *   [group1-item1, group1-item2],
     *   [group2-item1],
     *   etc
     * ]
     * example 2:
     * ("group1-item1" | "group2-item1, group2-item2" | empty | empty)
     * becomes
     *   [group1-item1],
     *   [group2-item1, group2-item2],
     *   etc
     * ]
     */
    importedGroups.map(importedGroup => {
      const importedGroupGroups = importedGroup
        .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/)
        .map(group => group
          .replace(/^"|"$/g, "")
          .trim(), // trims leading and trailing whitespace(s)
        )
        .filter(group => !!group)
      if (importedGroupGroups.length) {
        rawGroups.push(importedGroupGroups)
      }
    })

    /**
     * populate array by copying last item in each array to make all arrays at least the size of the following array
     *
     * example 1: no changes
     * example 2:
     * [
     *   [group1-item1, group1-item1],
     *   [group2-item1, group2-item2],
     *   etc
     * ]
     */
    let diffCount = 0
    if (rawGroups[3]) {
      if (rawGroups[3].length > rawGroups[2].length) {
        if (!diffCount) diffCount = rawGroups[3].length - rawGroups[2].length
        for (let i = rawGroups[2].length - 1; i < diffCount; i++) {
          rawGroups[2].push(rawGroups[2][i])
        }
      }
    }
    if (rawGroups[2]) {
      if (rawGroups[2].length > rawGroups[1].length) {
        if (!diffCount) diffCount = rawGroups[2].length - rawGroups[1].length
        for (let i = rawGroups[1].length - 1; i < diffCount; i++) {
          rawGroups[1].push(rawGroups[1][i])
        }
      }
    }
    if (rawGroups[1]) {
      if (rawGroups[1].length > rawGroups[0].length) {
        if (!diffCount) diffCount = rawGroups[1].length - rawGroups[0].length
        for (let i = rawGroups[0].length - 1; i < diffCount; i++) {
          rawGroups[0].push(rawGroups[0][i])
        }
      }
    }

    const groups: string[][] = []

    /**
     * combine and distribute rawGroup arrays
     *
     * result will have arrays grouped by item numbers
     * (building on examples from above)
     * example 1:
     * [
     *   [group1-item1, group2-item1].
     *   [group1-item2],
     *   etc
     * ]
     * example 2, previous group-item can carry over if omitted:
     * [
     *   [group1-item1, group2-item1],
     *   [group1-item1, group2-item2],
     *   etc
     * ]
     */
    if (rawGroups[0]) { // first terms
      for (const index in rawGroups[0]) { // each first term
        groups.push([rawGroups[0][index]]) // start new group for each term in rowGroups[0]
      }
    }
    if (rawGroups[1]) { // second terms
      for (const index in rawGroups[1]) { // each second term
        groups[index].push(rawGroups[1][index])
        // add each term in rawGroups[1] to each corresponding group as second term
      }
    }
    if (rawGroups[2]) {
      for (const index in rawGroups[2]) { // each third term
        groups[index].push(rawGroups[2][index])
        // add each term in rawGroups[2] to each corresponding group as third term
      }
    }
    if (rawGroups[3]) {
      for (const index in rawGroups[3]) { // each fourth term
        groups[index].push(rawGroups[3][index])
        // add each term in rawGroups[3] to each corresponding group as fourth term
      }
    }

    return groups
  }

  saveContentDocs(contentDocs: ContentDocs) {
    const batch = writeBatch(this.firestore)
    Object.entries(contentDocs)
      .map(([docId, contentDoc]) => {
        return batch.set(
          doc(collection(this.firestore, "content"), docId),
          { ...contentDoc },
          { merge: true },
        )
      })
    return batch.commit()
  }

  addContentToDocs(contentType: ContentType, groupOrItemOrMenuItems: Group | Item | MenuItem[], contentDocs: ContentDocs) {
    const docs = { ...contentDocs }
    let docAdded = false
    const groupName = contentType
      .replace("ROOT_", "")
      .toLowerCase()
      .replace("_", "-")
    switch (contentType) {
      case ContentType.PRODUCT:
      case ContentType.PAGE:
        break
      case ContentType.MENU: {
        const contentDocId = groupName + "-0"
        docs[contentDocId] = {
          id: contentDocId,
          content: groupOrItemOrMenuItems as MenuItem[],
          type: ContentType.MENU,
        } as ContentDoc
      }
        break
      case ContentType.ROOT_GROUP:
      case ContentType.GROUP:
      case ContentType.ITEM: {
        const content = groupOrItemOrMenuItems as Group | Item
        if (!docAdded) {
          Object.entries(docs).map(contentDocEntry => {
            const contentDoc = contentDocEntry[1]
            const contentMap = contentDoc.content as Groups | Items
            if (contentMap[content.sku]) {
              contentMap[content.sku] = content
              docs[contentDocEntry[0]] = contentDoc
              docAdded = true
            }
          })
        }
        if (!docAdded) {
          Object.entries(docs).map(contentDocEntry => {
            const contentMap = contentDocEntry[1].content as Groups | Items
            // 420kb seems to be max size
            // 1400 items are 420kb without images and descriptions, might double if all get descriptions
            // 750 groups are 350kb without images and descriptions, probably won't get many descriptions
            let limit = 0
            switch (contentType) {
              case ContentType.ITEM:
                limit = 800
                break
              case ContentType.GROUP:
              case ContentType.ROOT_GROUP:
                limit = 400
            }
            if (Object.keys(contentMap).length < limit) { // 420kb seems to be max size
              contentMap[content.sku] = content
              docs[contentDocEntry[0]] = contentDocEntry[1]
              docAdded = true
            }
          })
        }
        /**
         * Add fragment to a new group if none of the existing groups have room for it.
         */
        if (!docAdded) {
          const contentDocId = groupName + "-" + Object.keys(docs).length.toString()
          docs[contentDocId] = {
            id: contentDocId,
            content: {
              [content.sku]: content,
            },
            type: contentType
              .replace("ROOT_", ""),
          } as ContentDoc
        }
      }
        break
    }
    return docs
  }


}
