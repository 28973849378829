import { Component, inject } from "@angular/core"
import { ref, Storage, uploadBytesResumable } from "@angular/fire/storage"
import { UtilsService } from "@shared"
import { collection, doc, Firestore, setDoc } from "@angular/fire/firestore"
import { ContentService } from "./content.service"

@Component({
  standalone: true,
  template: `
    <input
      class="file-input file-input-bordered"
      id="file-input"
      type="file"
      accept=""
      (change)="fileChangeEvent($event)"
    >
  `,
  selector: "e2e-import",
})
export class ImportComponent {
  private storage = inject(Storage)
  private utilsService = inject(UtilsService)
  private firestore = inject(Firestore)
  private contentService = inject(ContentService)

  fileChangeEvent(event: Event) {
    const target = event.target as HTMLInputElement
    const files = target.files as FileList
    /**
     * Save original data to storage bucket
     */
    this.contentService.randomFilename.set(this.utilsService.createId())
    this.uploadToBucket(files[0])
  }

  uploadToBucket(file: File) {
    const filename_array = file.name.split(".")
    const filenameExtension = filename_array[filename_array.length - 1]
    if (filenameExtension !== "xlsx") {
      alert("failed to upload file, please try again with valid spreadsheet file")
    }
    if (filenameExtension === "xlsx") {
      this.contentService.processSpreadsheet(file)
      const spreadsheetRef = "imports/" + this.contentService.randomFilename() + ".xlsx"
      const storageRef = ref(this.storage, spreadsheetRef)
      uploadBytesResumable(
        storageRef,
        file,
        {
          cacheControl: "public, max-age=31104000",
        },
      )
        .then((snap) => {
          if (snap && snap.state === "success") {
            setDoc(
              doc(collection(this.firestore, "content"), "content"),
              { spreadsheetRef: spreadsheetRef },
              { merge: true },
            )
              .then(() => {
                // this.processStorageSpreadsheet(file)
              })
              .catch((error) => {
                console.log(error)
                alert("failed to save storage filename, please try again")
              })
          }
        })
    }
  }

}
