import { computed, inject, Injectable, signal } from "@angular/core"
import { idFromText } from "@shared"
import { convertToImageObject } from "../../../utilities/convert-to-image-object.utility"
import { sortByField } from "util/sort"
import { ContentService, Group, Groups, Item } from "../../import/content.service"
import { MenuItem } from "../../import/firestore-read.service"
import { UtilRouteListenerService } from "util/route-listener"

export interface GroupImage {
  src: string
  thumbnailImageSrc: string
  alt: string
  title: string
}

export interface GroupView {
  groupRefs: string[] // by sku
  itemRefs: string[] // by sku
  description: string
  groups: string[]
  sku: string // unique id
  title: string
  children: GroupView[]
  images: GroupImage[]
  menuItem: MenuItem
}

type Breadcrumb = {
  label: string
  routerLink: string
}

@Injectable({
  providedIn: "root",
})
export class GroupsService {
  private routeService = inject(UtilRouteListenerService)
  private contentService = inject(ContentService)

  scrollToProducts = signal<number>(0)
  scrollToSearch = signal<number>(0)
  scrollToTop = signal<number>(0)

  pathSegments = computed(() => this.routeService.pathSegments().slice(1))

  /**
   * find the group.groups that exactly matches the url path segments
   */
  group = computed(() => Object.values(this.contentService.groups_keyed())
    .find(group =>
      group.groups.length === this.pathSegments().length
      && group.groups
        .every((group, index) => {
            return idFromText(group) === idFromText(this.pathSegments()[index])
          },
        )),
  )

  groupItemsSorted = computed(() => sortByField("title", this.groupItems(),
    { byShoeSize: true, reverse: false }))

  private groupItems = computed(() => this.group()?.itemRefs
      .map(itemRef => this.contentService.items_map().get(itemRef))
      .filter(Boolean) as Item[]
    || [],
  )

  private groupImages = computed(() =>
    Array.from(new Set([
      ...this.getGroupImages(this.group(), this.contentService.groups_keyed()),
      ...this.groupItemsSorted().flatMap(item => item.images),
    ])),
  )

  groupImageObjects = computed(() =>
    this.pathSegments().length && this.group()
    && this.groupImages().map(image => convertToImageObject(image))
    ||
    !this.pathSegments().length && !this.group()
    && Object.values(this.contentService.groups_keyed())
      .filter(group => group.groups.length === 1)
      // .filter(group => group.images.length)
      .flatMap(group => group.images.map(image => convertToImageObject(image)))
    || [],
  )

  groupsViewSorted = computed(() => sortByField("title", this.groupsView()))

  private groupsView = computed(() =>
    this.pathSegments().length
    && this.group()?.groupRefs.length
    && this.group()?.groupRefs
      // .filter(groupRef => this.firestoreReadService.groups()[groupRef]?.itemRefs.length || this.firestoreReadService.groups()[groupRef].groupRefs.length)
      .map(groupRef => this.contentService.groups_map().get(groupRef))
      .filter(Boolean)
      .map(group => this.buildGroupVM(group as Group, this.groupImageObjects()))
    // .sort((a, b) => sort(a.title, b.title))
    ||
    (!this.pathSegments().length && !this.group()) // root view
    && Object.values(this.contentService.groups_keyed())
      .filter(group => group.groups.length === 1)
      // .filter(group => group.images.length)
      .flatMap(group => this.buildGroupVM(group, this.groupImageObjects()))
    // .sort((a, b) => sort(a.title, b.title))
    ||
    (this.pathSegments().length || !this.group()) // search result?
    && Object.values(this.contentService.groups_keyed())
      .filter(group => {
        return this.pathSegments()
          .map(pathSegment => this.routeService.queryParams()?.exactMatch === "true" ? pathSegment : pathSegment.split(" "))
          .flat()
          .find(term =>
            (
              this.routeService.queryParams()?.onlyProducts !== "true"
              &&
              // category search
              group.title.toLowerCase().includes(term.toLowerCase())
            )
            ||
            (
              this.routeService.queryParams()?.onlyCategories !== "true"
              &&
              // product search
              group.itemRefs
                .map(itemRef => this.contentService.items_map().get(itemRef))
                .find(item =>
                  item?.title.toLowerCase().includes(term.toLowerCase())
                  ||
                  item?.description.toLowerCase().includes(term.toLowerCase())
                )
            ),
          )
      })
      // .filter(group => group.images.length)
      .flatMap(group => this.buildGroupVM(group, this.groupImageObjects()))
    // .sort((a, b) => sort(a.title, b.title))
    || [],
  )

  breadcrumbs = computed<Breadcrumb[]>(() => {
    const breadcrumbSegments: string[] = []
    return (
      this.routeService.pathSegments().length &&
      this.routeService.pathSegments()
        .map(pathSegment => {
          const getGroupLabel = (breadcrumbSegments: string[], groups: Groups): string | undefined => {
            const group = Object.values(groups).find(
              (group) => idFromText(group.groups.join("_")) === breadcrumbSegments.join("_"),
            )
            return group?.groups.slice(-1)[0]
          }
          breadcrumbSegments.push(pathSegment)
          return {
            label: getGroupLabel(
              breadcrumbSegments.slice(1),
              this.contentService.groups_keyed(),
            ) || pathSegment,
            routerLink: "/" + breadcrumbSegments.join("/"),
          }
        }).slice(1) || [{
        label: "All Products",
        routerLink: "/products",
      }]
    )
  })

  lastBreadcrumb = computed(() => this.breadcrumbs().slice(-1)[0] || "")

  private getGroupImages(group: Group | undefined, groups: Groups): string[] {
    if (group) {
      if (group.images.length) return group.images
      const parentGroup = this.getGroup(group.groups.slice(0, -1), groups)
      if (parentGroup) return this.getGroupImages(parentGroup, groups)
    }
    return []
  }

  private getGroup(pathSegments: string[], groups: Groups): Group | undefined {
    return Object.values(groups)
      .find(group =>
        group.groups.length === pathSegments.length
        && group.groups
          .every((group, i) =>
            idFromText(group) === idFromText(pathSegments[i]),
          ))
  }

  private buildGroupVM(group: Group, groupImageObjects: GroupImage[]): GroupView {
    const parentGroups = group?.groups || []
    const parentGroupsId = parentGroups
      .map(linkSegment => idFromText(linkSegment))
      .slice(0, -1)
      .join("/")
    let label = ""
    if (parentGroupsId !== this.routeService.url().split("/").slice(2).join("/")) {
      label = parentGroups
        .slice(0, -1)
        .join(" > ")
    }
    return {
      ...group,
      images: groupImageObjects,
      menuItem: {
        label,
        // title: group.title,
        routerLink: "/products/" + [...parentGroups, group.title]
          .map(linkSegment => idFromText(linkSegment))
          .slice(0, -1)
          .join("/"),
      },
      children: [],
    }
  }

  setScrollToProducts() {
    // this.scrollToProducts.set(Date.now())
  }

  setScrollToSearch() {
    this.scrollToSearch.set(Date.now())
  }

  setScrollToTop() {
    // this.scrollToTop.set(Date.now())
  }

}
