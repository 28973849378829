import { Component, computed, effect, inject, input } from "@angular/core"
import { ImportComponent } from "../import/import.component"
import { UiInheritDirective } from "ui/inherit"
import { FeatureSharedLoginComponent, FeatureSharedLoginService } from "feature/shared/login"
import { DataAccessFirebaseAuthService } from "data-access/firebase-auth"
import { ProviderType } from "util/auth-provider"
import { GroupsService } from "../products/groups/groups.service"
import { JsonPipe } from "@angular/common"

@Component({
  imports: [
    UiInheritDirective,
    ImportComponent,
    FeatureSharedLoginComponent,
    JsonPipe,
  ],
  standalone: true,
  template: `
    <div class="column">
      <lib-feature-shared-login/>
      @if (loggedIn() && isAdmin()) {
        <e2e-import inherit/>
        {{ afUser() | json }}
      }
    </div>
  `,
  selector: "e2e-login",
})
export class LoginComponent {
  private groupService = inject(GroupsService)
  private featureSharedLoginService = inject(FeatureSharedLoginService)
  private authDataAccessService = inject(DataAccessFirebaseAuthService)

  loggedIn = computed(() => !!this.authDataAccessService.afUser())
  afUser = computed(() => this.authDataAccessService.afUser())

  providerTypes = input<ProviderType[]>([
    // ProviderType.APPLE,
    // ProviderType.FACEBOOK,
    ProviderType.GOOGLE,
    // ProviderType.GITHUB,
    // ProviderType.MICROSOFT,
    // ProviderType.TWITTER
    ProviderType.PASSWORD,
  ])

  afUserEmail = this.authDataAccessService.afUserEmail
  isAdmin = computed(() =>
    this.afUserEmail() === "lowell@digitalfarrier.com" ||
    this.afUserEmail() === "robertduggan2023@gmail.com" ||
    this.afUserEmail() === "robertduggan@rtduggan.com"
  )

  constructor() {
    this.featureSharedLoginService.providerTypes.set(this.providerTypes())
    /**
     * scroll to top when component loads
     * but not on initial app-load
     * only when clicking menu link for this route
     * whether on this route already or navigating to it
     */
    effect(() => {
      if (this.groupService.scrollToTop()) {
        // scrollToTop("smooth", 0, 0)
      }
    })
  }
}
