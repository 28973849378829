import { Component, effect, HostListener, inject, input, untracked } from "@angular/core"
import { UtilBreakpointsService } from "./util-breakpoints.service"

@Component({
  standalone: true,
  template: ``,
  selector: 'lib-util-breakpoints',
})
export class UtilBreakpointsComponent {
  private breakpointService = inject(UtilBreakpointsService)

  debugBreakpoints = input.required<boolean>()

  constructor() {
    effect(() => {
      const debug = this.debugBreakpoints()
      untracked(() => this.breakpointService.debug.set(debug))
    })
  }

  @HostListener("window:resize")
  resizeEvent(): void {
    if (this.debugBreakpoints()) {
      const width = window.innerWidth
      if (width % 10 === 0) {
        console.log(width)
      }
    }
  }
}
