import { Component, input, output } from "@angular/core"
import { FormsModule } from "@angular/forms"

@Component({
  imports: [
    FormsModule,
  ],
  standalone: true,
  template: `
    <input
      class="checkbox checkbox-secondary"
      type="checkbox"
      [name]="name()"
      [id]="identifier()"
      [value]="identifier()"
      [ngModel]="selected()"
      (ngModelChange)="changes.emit($event)"
    />
    <label [for]="identifier()">
      {{ label() }}
    </label>
  `,
  selector: "lib-ui-checkbox",
})
export class UiCheckboxComponent {
  name = input.required<string>()
  label = input.required<string>()
  identifier = input.required<string>()
  selected = input<boolean>(false)
  changes = output<boolean>()
}

