import { Component, computed, inject, input, signal } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { UtilRouteListenerService } from "util/route-listener"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <div
      class="cursor-pointer"
      [class]="wrapperClasses()"
      style="max-width: 200px; transition: transform 0.3s ease, box-shadow 0.3s ease;"
      (click)="navigateTo(image().alt, image().exactMatch)"
      (mouseover)="mouseover.set(true)"
      (mouseout)="mouseover.set(false)"
    >
      <img
        [alt]="image().alt"
        style="object-fit: cover"
        [style.aspect-ratio]="image().aspectRatio"
        priority=""
        [ngSrc]="image().fileName"
        [height]="image().height"
        [width]="image().width"
      >
    </div>
  `,
  selector: "e2e-brand-image",
})
export class BrandImageComponent {
  private routeService = inject(UtilRouteListenerService)

  image = input.required<{ exactMatch?: string, width: number, height: number, aspectRatio: number, alt: string, fileName: string }>()

  mouseover = signal(false)

  wrapperClasses = computed(() =>
    this.mouseover() && "shadow-lg scale-110 border border-2" || ""
  )

  navigateTo(path: string, exactMatch: string | null = null) {
    this.routeService.changeRoute(["products/" + path], { exactMatch })
    // exactMatch
    //   ? this.routeService.changeRoute(["products/" + path], { exactMatch })
    //   : this.routeService.changeRoute(["products/" + path])
  }
}
