export function scrollToTop(behavior?: ScrollBehavior, top?: number, left?: number) {
  window.scrollTo({ behavior, top, left })
}

export function scrollIntoView(element: HTMLElement | undefined, behavior?: ScrollBehavior, block?: ScrollLogicalPosition, inline?: ScrollLogicalPosition) {
  if (element && !isElementInViewport(element)) {
    element.scrollIntoView({ behavior, block, inline })
  }
}

export function isElementInViewport(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function scrollToSmooth(endX: number, endY: number) {
  const startX = window.scrollX || window.scrollX
  const startY = window.scrollY || window.scrollY
  const distanceX = endX - startX
  const distanceY = endY - startY
  const duration = 800 // Duration in ms, adjust this value to control the scroll speed
  const startTime = performance.now()

  function scroll(currentTime: number) {
    const timeElapsed = currentTime - startTime
    const progress = Math.min(timeElapsed / duration, 1)
    window.scrollTo(
      startX + distanceX * progress,
      startY + distanceY * progress,
    )
    if (progress < 1) {
      window.requestAnimationFrame(scroll)
    }
  }

  window.requestAnimationFrame(scroll)
}
