import { Component, computed, input, output } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { GroupImage } from "./groups/groups.service"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  providers: [],
  standalone: true,
  template: `
    <div
      style="position: absolute; top: 50%; left: 0; width: 100%; height: 100%; transition: z-index 250ms"
      [style.z-index]="style().zIndex"
    >
      <img
        class="bg-base-100"
        style="position: relative; left: 0; transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43); max-height: 100%"
        [style.opacity]="style().opacity"
        [style.transform]="style().transform"
        
        [style.cursor]="style().cursor"
        [ngSrc]="imageObject().src"
        [alt]="imageObject().alt"
        width="350"
        height="350"
        (click)="changeImage.emit(imageObject())"
      />
    </div>

  `,
  selector: "e2e-slider-image",
})
export class SliderImageComponent {

  imageObject = input.required<GroupImage>()
  index = input.required<number>()
  sliderIndex = input.required<number>()
  length = input.required<number>()

  changeImage = output<GroupImage>()

  style = computed(() => {
    const sliderIndex = this.sliderIndex()
    switch (this.index()) {
      case sliderIndex - 2: // previous
        return {
          cursor: "pointer",
          opacity: 0.3,
          transform: "translateX(-60%) translateY(0%) scale(0.6)",
          zIndex: 1,
        }
      case sliderIndex - 1: // previous
        return {
          cursor: "pointer",
          opacity: 0.3,
          transform: "translateX(-30%) translateY(-30%) scale(0.8)",
          zIndex: 2,
        }
      case sliderIndex: // selected
        return {
          cursor: "grab",
          opacity: 1,
          transform: "translateX(0%) translateY(-50%) scale(1)",
          zIndex: 3,
        }
      case sliderIndex + 1: // next
        return {
          cursor: "pointer",
          opacity: 0.3,
          transform: "translateX(30%) translateY(-30%) scale(0.8)",
          zIndex: 2,
        }
      case sliderIndex + 2: // next
        return {
          cursor: "pointer",
          opacity: 0.3,
          transform: "translateX(60%) translateY(-0%) scale(0.6)",
          zIndex: 1,
        }
      default: {
        if (this.index() < sliderIndex) {
          return {
            cursor: "default",
            opacity: 0,
            transform: "translateX(-80%) translateY(50%) scale(0.4)",
            zIndex: 0,
          }
        } else {
          return {
            cursor: "default",
            opacity: 0,
            top: 50,
            transform: "translateX(80%) translateY(50%) scale(0.4)",
            zIndex: 0,
          }
        }
      }
    }
  })

}
