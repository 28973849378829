import { Component, computed, inject, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { FeatureSharedLoginService } from "./feature-shared-login.service"

@Component({
  imports: [
    FormsModule,
  ],
  standalone: true,
  template: `
    <div class="column">
      <div class="row" style="justify-content: center">
        <button
          class="btn"
          [class]="newAccountButtonClass()"
          (click)="createNewAccount()"
        >
          Create New Account
        </button>
        <button
          class="btn"
          [class]="existingAccountButtonClass()"
          (click)="useExistingAccount()"
        >
          Use Existing Account
        </button>
      </div>
      <div class="center">
        @if (newAccount()) {
          Create New Account
        }
        @if (!newAccount()) {
          Sign in with Existing Account
        }
      </div>
      <input
        class="input"
        placeholder="email address"
        type="email"
        [(ngModel)]="email"
      />

      <div>
        <input
          class="input"
          placeholder="password"
          style="width: 100%"
          [type]="passwordVisible ? 'text' : 'password'"
          [(ngModel)]="password"
        />
        <div
          class="center"
          style="color: #1890ff"
          (click)="passwordVisible = !passwordVisible"
        >
          {{ !passwordVisible ? "show password" : "hide password" }}
        </div>
      </div>

      @if (email && password) {
        <div class="center">
          <button
            class="btn"
            [disabled]="!email || !password"
            (click)="login()"
          >
            Sign In
          </button>
        </div>
      }
    </div>
  `,
  selector: "lib-feature-shared-sign-in-with-password",
})
export class FeatureSharedSignInWithPasswordComponent {
  private featureSharedLoginService = inject(FeatureSharedLoginService)

  password = ""
  passwordVisible = false
  email = ""
  newAccount = signal(true)
  newAccountButtonClass = computed(() => this.newAccount() ? "btn-primary" : "")
  existingAccountButtonClass = computed(() => !this.newAccount() ? "btn-primary" : "")

  login() {
    if (this.email && this.password) {
      this.featureSharedLoginService.withPassword(this.email, this.password)
      // switch (this.newAccount) {
      //   case true:
      //     this.userAuthService.userCreateUserWithEmailAndPassword(this.email, this.password)
      //     break
      //   case false:
      //     this.userAuthService.userSignInWithEmailAndPassword(this.email, this.password)
      // }
    }
  }

  createNewAccount() {
    this.newAccount.set(true)
  }

  useExistingAccount() {
    this.newAccount.set(false)
  }
}
