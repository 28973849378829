import { GroupImage } from "../components/import/content.service"

export interface Options {
  source: "assets" | "bucket"
}

export const convertToImageObject = (image: string, options: Options = { source: "bucket" } ): GroupImage => {
  let imageUrl = ""
  switch (options.source) {
    case "assets":
      imageUrl = "assets/" + image
      // imageUrl = environment.imagekit_urlEndpoint + "/assets/" + image
      break
    case "bucket":
      // imageUrl = "https://storage.googleapis.com/" + environment.firebaseConfig.storageBucket + "/images/" + image
      imageUrl = "images/" + image
      // imageUrl = environment.imagekit_urlEndpoint + "/images/" + image
      break
  }
  return {
    src: imageUrl,
    thumbnailImageSrc: imageUrl,
    alt: "image",
    title: "image"
  }
}
