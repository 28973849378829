import { Component, inject, model, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { UtilRouteListenerService } from "util/route-listener"
import { UiCheckboxComponent } from "ui/checkbox"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    FormsModule,
    UiCheckboxComponent,
    UiInheritDirective,
  ],
  standalone: true,
  template: `
    <div>
      <div class="row">
        <input
          name="search"
          class="input input-bordered join-item"
          placeholder="Search"
          [ngModel]="searchInput()"
          (ngModelChange)="searchInput.set($event)"
          (keyup.enter)="searchButton()"
        />
        <!--
                  <select class="select select-bordered join-item">
                    <option disabled selected>Filter</option>
                    <option>test 1</option>
                  </select>
        -->
        <div class="indicator">
          <span class="indicator-item badge badge-accent">
            new
          </span>
          <button
            class="btn join-item"
            (click)="searchButton()"
          >
            Search
          </button>
        </div>
        <div class="row flex-nowrap">
          <lib-ui-checkbox
            name="exactMatch"
            [selected]="exactMatch()"
            label="Exact Match"
            identifier="exactMatch"
            (changes)="exactMatch.set(!exactMatch())"
            inherit
          />
          <lib-ui-checkbox
            name="categories"
            [selected]="categories()"
            label="Category Titles"
            identifier="categories"
            (changes)="toggleCategories()"
            inherit
          />
          <lib-ui-checkbox
            name="products"
            [selected]="products()"
            label="Product Descriptions"
            identifier="products"
            (changes)="toggleProducts()"
            inherit
          />
        </div>

      </div>
    </div>
  `,
  selector: "e2e-search",
})
export class SearchComponent {
  private routeService = inject(UtilRouteListenerService)

  searchInput = model("")

  exactMatch = signal(true)
  categories = signal(true)
  products = signal(true)

  searchButton() {
    // this.appService.searchTerm.set(this.searchInput())
    this.routeService.changeRoute(
      ["products/" + this.searchInput()],
      {
        exactMatch: this.exactMatch() ? true : null,
        onlyCategories: this.categories() && !this.products() ? true : null,
        onlyProducts: !this.categories() && this.products() ? true : null,
      }
    )
  }

  toggleCategories() {
    if (this.categories() && !this.products()) {
      this.products.set(true)
    }
    this.categories.set(!this.categories())
  }

  toggleProducts() {
    if (this.products() && !this.categories()) {
      this.categories.set(true)
    }
    this.products.set(!this.products())
  }

}
