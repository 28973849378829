import { Component, inject } from "@angular/core"
import { ContentService } from "../import/content.service"
import { RouterLink, RouterLinkActive } from "@angular/router"
import { GroupsService } from "./groups/groups.service"

@Component({
  imports: [
    RouterLink,
    RouterLinkActive,
  ],
  standalone: true,
  template: `
    <div class="row-center">
      @for (menuItem of menuItems(); track menuItem.label) {
        <a
          class="btn"
          [routerLink]="menuItem.routerLink"
          routerLinkActive="btn-primary"
          (click)="scrollToSearch()"
        >
          {{ menuItem.label }}
        </a>
      }
    </div>
  `,
  selector: "e2e-categories",
})
export class CategoriesComponent {
  private contentService = inject(ContentService)
  private groupService = inject(GroupsService)

  menuItems = this.contentService.menuItems_array

  scrollToSearch() {
    this.groupService.setScrollToSearch()
  }
}
