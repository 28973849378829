import { ErrorHandler, inject, Injectable, NgZone } from "@angular/core"
import { MessageService } from "primeng/api"

/**
 * handle errors caught globally by the Angular internal try/catch error handler
 */

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  private zone = inject(NgZone)
  private messageService = inject(MessageService)

  handleError(error: Error) {

    console.log(error.stack)

    this.zone.run(() => {
/*
      const event: ExceptionEvent = {
        eventName: EventName.EXCEPTION,
        eventParams: {
          class: this.constructor.name,
          function: "handleError",
          action: "global zone.js error handler",
          description: String(error),
          stack: error.stack,
          fatal: false
        }
      }
      this.analyticsService.sendExceptionEvent(event)
*/

/*
      this.messageService.add({
        key: "top-level",
        severity: "error",
        summary: "Error was detected!",
        detail: error.stack,
        sticky: true
      })
*/
    })
  }
}
