import { Component, computed, inject, input, signal } from "@angular/core"
import { faEclipse, faMoon, faSun } from "@fortawesome/pro-solid-svg-icons"
import { FormsModule } from "@angular/forms"
import { UiIconComponent } from "ui/icon"
import { prefersDarkSchemeQuery, ThemeMode, UiThemeService } from "./ui-theme.service"


@Component({
  imports: [
    UiIconComponent,
    FormsModule,
  ],
  standalone: true,
  template: `
    @for (theme of themes(); track theme) {
      <input
        type="radio"
        name="theme-dropdown"
        class="theme-controller hidden"
        [value]="theme.name"
        [checked]="theme.name === selectedTheme().name"
      />
    }
    <button
      class="btn text-xl"
      [class]="buttonClasses()"
      title="Dark/Light/Auto"
      (click)="switchMode()"
    >
      @if (autoMode()) {
        <lib-ui-icon [icon]="faEclipse"/>
      }
      @if (darkMode()) {
        <lib-ui-icon [icon]="faMoon"/>
      }
      @if (lightMode()) {
        <lib-ui-icon [icon]="faSun"/>
      }
    </button>
  `,
  selector: "lib-ui-theme-mode-switcher",
})
export class UiThemeModeSwitcherComponent {
  private themeService = inject(UiThemeService)

  buttonClasses = input("")

  themes = this.themeService.themes
  selectedTheme = this.themeService.selectedTheme

  autoMode = computed(() => this.themeService.selectedMode() === ThemeMode.AUTO)
  darkMode = computed(() => this.themeService.selectedMode() === ThemeMode.DARK)
  lightMode = computed(() => this.themeService.selectedMode() === ThemeMode.LIGHT)


  constructor() {
    prefersDarkSchemeQuery.addEventListener("change", () => {
      if (this.themeService.selectedMode() === ThemeMode.AUTO) {
        this.setMode(ThemeMode.AUTO)
      }
    })
    const mode: ThemeMode = localStorage.getItem("mode") as ThemeMode
    switch (mode) {
      case ThemeMode.AUTO:
      case ThemeMode.DARK:
      case ThemeMode.LIGHT:
        this.setMode(mode)
        break
      default: {
        const selectedTheme = this.themes().find(theme => theme.name === localStorage.getItem("theme"))
        if (selectedTheme) {
          this.selectedTheme.set(selectedTheme)
        }
        if (!selectedTheme) {
          this.setMode(ThemeMode.AUTO)
        }
      }
    }
  }

  switchMode() {
    const mode_map = new Map<ThemeMode, ThemeMode>([
      [ThemeMode.AUTO, ThemeMode.LIGHT],
      [ThemeMode.LIGHT, ThemeMode.DARK],
      [ThemeMode.DARK, ThemeMode.AUTO],
    ])
    this.setMode(mode_map.get(this.themeService.selectedMode()) || ThemeMode.AUTO)
  }

  setMode(mode: ThemeMode) {
    this.themeService.selectedMode.set(mode)
    switch (mode) {
      case ThemeMode.AUTO:
        this.themeService.setSelectedTheme(this.themes()[0])
        break
      case ThemeMode.LIGHT:
        this.themeService.setSelectedTheme(this.themes()[1])
        break
      case ThemeMode.DARK:
        this.themeService.setSelectedTheme(this.themes()[2])
        break
    }
    localStorage.setItem("mode", mode)
  }

  protected readonly faMoon = faMoon
  protected readonly faSun = faSun
  protected readonly faEclipse = faEclipse
}
