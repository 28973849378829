import { Component, inject, OnInit } from "@angular/core"
import { CheckoutService } from "../checkout/checkout.service"

@Component({
  imports: [],
  standalone: true,
  template: `
    <div class="column-center">
      <div class="column">
        <div>
          Your order has been received at Duggan Farrier Supplies.
        </div>
        <div>
          We will get it on the truck today and you should receive it in 1 – 3 days.
        </div>
        <div>
          If you have any questions or concerns, please call the store at
          <a class="btn btn-primary btn-sm" href="tel:17634275850">
            763-427-5850
          </a>
        </div>
        <div>
          Thank you for your business, We appreciate it.
        </div>
        <div class="pt-6">
          Have a great day,
        </div>
        <div>
          Robert Duggan
        </div>
      </div>
    </div>
  `,
  selector: "e2e-success",
})
export class SuccessComponent implements OnInit {
  private cartService = inject(CheckoutService)

  ngOnInit() {
    this.cartService.emptyCart()
  }

}
