import { inject, Injectable } from "@angular/core"
import { collection, doc, Firestore, } from "@angular/fire/firestore"

export function idFromText(name: string) {
  return name?.toLowerCase()
    .replace(/[^A-Za-z0-9]/g, "_")
    .replace(/_+_*/g, "_")
}

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private firestore = inject(Firestore)

  createId(): string {
    return doc(collection(this.firestore, "_")).id
  }

  sort(a: unknown, b: unknown) {
    if (a === undefined || b === undefined || a === null || b === null) {
      return 0
    }
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
  }

}
