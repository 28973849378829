import { Component, computed, input, signal } from "@angular/core"
import { RouterLink } from "@angular/router"
import { GroupView } from "./groups.service"

@Component({
  imports: [
    RouterLink,
  ],
  standalone: true,
  template: `
    @if (groupView(); as groupView) {
      <a
        class="card bg-primary text-primary-content h-full center p-3"
        style="width: 266px; transition: transform 0.3s ease, box-shadow 0.3s ease;"
        [class]="wrapperClasses()"
        [routerLink]="groupView.menuItem.routerLink"
        (mouseover)="mouseover.set(true)"
        (mouseout)="mouseover.set(false)"
      >
        <div class="column-center justify-center flex-1">
          <h2 class="card-title">
            {{ groupView.title }}
          </h2>
        </div>
        <div>
          {{ groupView.menuItem.label }}
        </div>
      </a>
      <!--
            <a
              style="display: inline-block; padding: 20px; border-radius: 8px; width: 100%; height: 100%; box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1)"
              [routerLink]="groupView.menuItem.routerLink"
            >
              <div class="column" style="align-items: center; justify-content: center; height: 100%; width: 100%">
                @if (groupView.images[0]) {
                  <div class="w-40 h-24 overflow-hidden">
                    <img
                      [alt]="groupView.title"
                      [src]="groupView.images[0].itemImageSrc"
                    />
                  </div>
                }
                <div class="text-center">
                  {{ groupView.title }}
                </div>
              </div>
            </a>
      -->
    }
  `,
  selector: "e2e-group-card",
})
export class GroupCardComponent {
  groupView = input.required<GroupView>()

  mouseover = signal(false)

  wrapperClasses = computed(() =>
    this.mouseover() && "shadow-lg scale-105 border border-2" || ""
  )

}
