import { Component } from "@angular/core"

@Component({
  imports: [],
  standalone: true,
  template: `
    <p>
      data-access-firebase-auth works!
    </p>
  `,
  selector: "lib-data-access-firebase-auth",
})
export class DataAccessFirebaseAuthComponent {

}
