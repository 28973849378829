import { Component } from "@angular/core"

@Component({
  imports: [],
  standalone: true,
  template: ``,
  selector: "lib-util-auth-provider",
})
export class UtilAuthProviderComponent {
}
