import { Directive, effect, ElementRef, inject, input } from "@angular/core"

@Directive({
  selector: "[libFlex]",
  standalone: true,
})
export class FlexStylesDirective {
  private elementRef = inject(ElementRef)

  e2eFlex = input<"column" | "row" | undefined>(undefined)

  constructor() {
    effect(() => {
      const element = this.elementRef.nativeElement as HTMLElement
      switch (this.e2eFlex()) {
        case "column":
          element.classList.add("column")
          break
        case "row":
          element.classList.add("row")
          break
        default:
      }
    })
  }

}
