export function sortByField(
  field: string,
  data: any[] = [],
  options: {
    reverse?: boolean,
    byShoeSize?: boolean
  } = {
    reverse: false,
    byShoeSize: false
  }
) {
  const cleanCopyData = [...data] as { [key: string]: string }[]

  if (options.byShoeSize) {
    return cleanCopyData
      .map(d => {
        return {
          ...d,
          [field]: d[field]
            .replace(/^000\s/, "-1 ")
            .replace(/^00\s/, "-2 ")
            .replace(/^0\s/, "-3 ")
            .replace(/\s000\s/, " -1 ")
            .replace(/\s00\s/, " -2 ")
            .replace(/\s0\s/, " -3 ")
            .replace(/\s000$/, " -1")
            .replace(/\s00$/, " -2")
            .replace(/\s0$/, " -3")
        }
      })
      .sort((a, b) => sort(a[field], b[field], options))
      .map(d => {
        return {
          ...d,
          [field]: d[field]
            .replace(/^-1\s/, "000 ")
            .replace(/^-2\s/, "00 ")
            .replace(/^-3\s/, "0 ")
            .replace(/\s-1\s/, " 000 ")
            .replace(/\s-2\s/, " 00 ")
            .replace(/\s-3\s/, " 0 ")
            .replace(/\s-1$/, " 000")
            .replace(/\s-2$/, " 00")
            .replace(/\s-3$/, " 0")
        }
      }) as any[]

  }

  return cleanCopyData
    .sort((a, b) => sort(a[field], b[field], options))
}

export function sort(
  a: unknown,
  b: unknown,
  options: {
    reverse?: boolean,
    byShoeSize?: boolean
  } = {
    reverse: false,
    byShoeSize: false
  }
) {
  if (a === undefined || b === undefined || a === null || b === null) {
    return 0
  }

  if (options.reverse) {
    if (b < a) {
      return -1
    }
    if (b > a) {
      return 1
    }
    return 0

  }

  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}
