import { Component, inject, input } from "@angular/core"
import { indexOf } from "lodash-es"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { UiIconComponent } from "ui/icon"
import { Theme, UiThemeService } from "./ui-theme.service"

@Component({
  imports: [
    UiIconComponent,
  ],
  standalone: true,
  template: `
    @for (theme of themes(); track theme) {
      <input
        type="radio"
        name="theme-dropdown"
        class="theme-controller hidden"
        [value]="theme.name"
        [checked]="theme.name === selectedTheme().name"
      />
    }
    @if (enable()) {
      <div class="row" style="position: fixed; bottom: 75px; right: 50px; z-index: 10000">
        <button class="btn btn-sm">
          {{ selectedTheme().name }}
        </button>
        <button
          class="btn btn-sm"
          (click)="previousTheme()"
        >
          <lib-ui-icon [icon]="faChevronLeft"/>
        </button>
        <button
          class="btn btn-sm"
          (click)="nextTheme()"
        >
          <lib-ui-icon [icon]="faChevronRight"/>
        </button>
      </div>
    }
  `,
  selector: "lib-ui-theme-switcher",
})
export class UiThemeSwitcherComponent {
  private themeService = inject(UiThemeService)

  enable = input(true)
  themes = this.themeService.themes
  selectedTheme = this.themeService.selectedTheme

  constructor() {
    const selectedTheme = this.themes().find(theme => theme.name === localStorage.getItem("theme"))
    if (selectedTheme) {
      this.setSelectedTheme(selectedTheme)
    }
  }

  nextTheme() {
    const themeIndex = indexOf(this.themes().map(theme => theme.name), this.selectedTheme().name)
    if (themeIndex < this.themes().length - 1) {
      this.setSelectedTheme(this.themes()[themeIndex + 1])
    } else {
      this.setSelectedTheme(this.themes()[0])
    }
  }

  previousTheme() {
    const themeIndex = indexOf(this.themes().map(theme => theme.name), this.selectedTheme().name)
    if (themeIndex > 0) {
      this.setSelectedTheme(this.themes()[themeIndex - 1])
    } else {
      this.setSelectedTheme(this.themes()[this.themes().length - 1])
    }
  }

  setSelectedTheme(selectedTheme: Theme) {
    this.themeService.setSelectedTheme(selectedTheme)
  }

  protected readonly faChevronLeft = faChevronLeft
  protected readonly faChevronRight = faChevronRight
}
