import { Component } from "@angular/core"
import { DataAccessFirebaseAuthUserManagerComponent } from "data-access/firebase-auth"

@Component({
  imports: [
    DataAccessFirebaseAuthUserManagerComponent,
  ],
  standalone: true,
  template: `
      <lib-user-manager/>
  `,
  selector: "e2e-users",
})
export class UsersComponent {

}
