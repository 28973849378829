import { Component } from "@angular/core"
import { Breakpoints } from "@angular/cdk/layout"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    <div class="column">
      @for (card of documentation; track card) {
        @if (card.title) {
          <div>
            <div>
              {{ card.title }}
            </div>
            @for (text of card.text; track text) {
              <div class="">
                {{ text }}
              </div>
            }
          </div>
        }
      }
    </div>
  `,
  selector: "e2e-documentation",
})
export class DocumentationComponent {
  documentation = [
    {
      title: "Checkout Requirements",
      text: [
        "New user account creates stripe customer and saves stripe customer id to firestore. This stripe customer id is required for checkout",
      ],
    },
    {
      title: "Checkout Data Flow",
      text: [
        "Using stripe customer id and line items, post Stripe.Checkout.Session to cloud function which returns Session response.",
        "Using the session id from the Session response, redirect user with stripeService.redirectToCheckout()",
        "User will be redirected back to success_url or cancel_url from the checkout page, defined earlier in the Stripe.Checkout.Session",
      ],
    },
    {
      title: "breakpoints",
      text: [
        "Web",
        Breakpoints.Web,
        "WebLandscape",
        Breakpoints.WebLandscape,
        "WebPortrait",
        Breakpoints.WebPortrait,
        "Tablet",
        Breakpoints.Tablet,
        "TabletPortrait",
        Breakpoints.TabletPortrait,
        "TabletLandscape",
        Breakpoints.TabletLandscape,
        "Handset",
        Breakpoints.Handset,
        "HandsetLandscape",
        Breakpoints.HandsetLandscape,
        "HandsetPortrait",
        Breakpoints.HandsetPortrait,
        "XSmall",
        Breakpoints.XSmall,
        "Small",
        Breakpoints.Small,
        "Medium",
        Breakpoints.Medium,
        "Large",
        Breakpoints.Large,
        "XLarge",
        Breakpoints.XLarge,
      ],
    },
    {
      title: "",
      text: ["", "", ""],
    },
    {
      title: "",
      text: ["", "", ""],
    },
    {
      title: "",
      text: ["", "", ""],
    },
    {
      title: "",
      text: ["", "", ""],
    },
  ]
}
