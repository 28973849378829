import { Component, computed, signal } from "@angular/core"
import { FaIconComponent } from "@fortawesome/angular-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"

@Component({
  imports: [FaIconComponent],
  providers: [],
  standalone: true,
  template: `
    <div
      class="column"
      style="position: relative; font-family: 'Archivo Black', sans-serif; color: #BD3B26"
      [style.background-color]="'rgba(255, 255, 255, 0.3)'"
      (mouseover)="mouseEvent.set($event.type)"
      (mouseout)="mouseEvent.set($event.type)"
    >
      <div
        style="transition: all 0.3s ease; padding: 12px"
        [style.letter-spacing.px]="styles().letterSpacing"
      >
        NEXT SECTION
        <fa-icon [icon]="faArrowRight"/>
      </div>
      <div
        style="position: absolute; width: 100%; height: 100%"
        [style.border-bottom]="styles().borderBottom"
        [style.border-top]="styles().borderTop"
        [style.transform]="styles().transform"
        [style.transition]="styles().transition"
      ></div>
    </div>
  `,
  selector: "lib-next-section-button",
})
export class NextSectionButtonComponent {
  mouseEvent = signal<string>("")
  styles = computed(() => {
    if (this.mouseEvent() === "mouseover") {
      return {
        borderBottom: "dashed 1px #BD3B26",
        borderTop: "dashed 1px #BD3B26",
        letterSpacing: 6,
        transform: "scale(1, 1)",
        transition: "transform 1s ease",
      }
    }
    return {
      borderBottom: "dashed 1px transparent",
      borderTop: "dashed 1px transparent",
      letterSpacing: 0.7,
      transform: "scale(0.1, 1)",
      transition: "transform 1s ease, border-color 1s ease",
    }
  })

  protected readonly faArrowRight = faArrowRight
}
