import { Component, inject, input } from "@angular/core"
import { FeatureSharedSignInProviderButtonComponent } from "./feature-shared-sign-in-provider-button.component"
import { FeatureSharedSignInWithPasswordComponent } from "./feature-shared-sign-in-with-password.component"
import { UiInheritDirective } from "ui/inherit"
import { FeatureSharedLoginService } from "./feature-shared-login.service"

@Component({
  imports: [
    FeatureSharedSignInProviderButtonComponent,
    FeatureSharedSignInWithPasswordComponent,
    UiInheritDirective,
  ],
  standalone: true,
  template: `
    <div class="row" style="justify-content: center">
      <div>
        Sign in with
      </div>
      @for (providerType of providerTypes(); track providerType; let last = $last) {
        <lib-feature-shared-sign-in-provider-button [providerType]="providerType"/>
        @if (!last) {
          or
        }
      }

    </div>

    <div>
    </div>


    @if (signInWithPassword()) {
      <div class="center">
        Sign in with Email and Password
      </div>
      <lib-feature-shared-sign-in-with-password/>
      <hr>
    }

  `,
  selector: "lib-feature-shared-sign-in",
})
export class FeatureSharedSignInComponent {
  private featureSharedLoginService = inject(FeatureSharedLoginService)

  providerTypes = this.featureSharedLoginService.providerTypes
  signInWithPassword = input.required<boolean>()
}
