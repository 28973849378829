import { Injectable } from "@angular/core"

export type EditorVisibility = {
  addContent?: boolean
  adminMode?: boolean
  styles?: boolean
  layout?: boolean
  status?: boolean
  settings?: boolean
}

@Injectable({
  providedIn: "root"
})
export class VisibilityService {

/*
  getVisibility(visibility: EditorVisibility, content: Content, row?: Field) {
    const hasRole = this.globalState.get("hasRole")
    visibility = this.contentVisibility(visibility, content, hasRole)
    if (row) {
      visibility = this.rowVisibility(visibility, content, row, hasRole)
    }

    if (visibility.adminMode) {
      visibility.addContent = true
      visibility.layout = true
      visibility.settings = true
      visibility.status = true
      visibility.styles = true
    }
    return visibility
  }

  contentVisibility(visibility: EditorVisibility, content: Content, hasRole: GlobalState["hasRole"]){
    visibility.addContent = true
    visibility.layout = true
    visibility.settings = true
    visibility.status = true
    visibility.styles = true

    if (content.settings.types.includes(ContentType.LOCAL_MAP)) {
      visibility.addContent = false
      visibility.layout = false
      visibility.settings = false
      visibility.status = false
      visibility.styles = false
    }

    return visibility
  }
*/

/*
  rowVisibility(visibility: EditorVisibility, content: Content, row: Field, hasRole: GlobalState["hasRole"]) {
    switch (row.field) {
      case FieldType.TEXT_AREA: {
        const textAreaField = row as TextArea
        visibility.styles = !!hasRole.EDITOR
          && textAreaField.style !== TextAreaStyleType.PLAIN_TEXT
          && !content.settings?.types?.includes(ContentType.LOCAL_MAP)
        // g.hasRole.EDITOR && textAreaStyleType !== TextAreaStyleType.PLAIN_TEXT && !contentSettings?.types?.includes(ContentType.LOCAL_MAP)
      }
        break
      case FieldType.IMAGE: {
        const imageField = row as Image
        visibility.styles = true
      }
        break
    }

    return visibility
  }
*/

}
