import { Component, input } from "@angular/core"
import { AnimationProp, FaIconComponent, FaProps } from "@fortawesome/angular-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

@Component({
  imports: [
    FaIconComponent
  ],
  standalone: true,
  template: `
    <div class="inline-block">
      <fa-icon [icon]="icon()" [animation]="animation()"/>
    </div>
  `,
  selector: "lib-ui-icon",
})
export class UiIconComponent {
  icon = input.required<IconProp>()
  animation = input<AnimationProp | undefined>(undefined)
}
