import { Component } from "@angular/core"
import { RouterLink } from "@angular/router"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [
    RouterLink,
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <div class="row px-20 py-8 items-start bg-base-300">
      <div class="column flex-1">
        <h3 class="text-xl">
          Contact Us
        </h3>
        <div>
          Duggan Farrier Supplies
          <br>
          6250 Riverdale Drive NW
          <br>
          Ramsey, MN 55303
          <br>
          Sales and Support:
          <a href="tel:17634275850">
            763-427-5850
          </a>
          <br>
          Email: 
          <a href="mailto:robertduggan@rtduggan.com">
            robertduggan&#64;rtduggan.com
          </a>
        </div>
      </div>
      <div class="flex-1">
        <h3 class="text-xl">
          More
        </h3>
        <div>
          <a routerLink="/events">
            Upcoming Events
          </a>
          <br>
          <a routerLink="/school">
            Horseshoeing School
          </a>
        </div>
      </div>
      <div class="column flex-1">
        <h3 class="text-xl">
          Website Security Policy
        </h3>
        <div>
          Your payment and personal information is always safe. Our Secure Sockets Layer (SSL)
          software is the industry standard and among the best software available today for secure
          commerce transactions. It encrypts all your personal information, including credit card
          number, name, and address, so that it cannot be read over the internet. We respect and are
          committed to protecting your privacy. We promise to never share your personal information
          or email address with any 3rd party.
        </div>
        <div>
          <img
            alt=""
            ngSrc="assets/credit-cards.jpg"
            priority=""
            style="position: relative"
            fill=""
          />
        </div>

      </div>
    </div>

  `,
  selector: "e2e-site-footer",
})
export class SiteFooterComponent {
}
