import { Component, computed, inject, input, signal } from "@angular/core"
import { UiInheritDirective } from "ui/inherit"
import {
  DataAccessFirebaseAuthService,
  DataAccessFirebaseAuthProfileService,
} from "data-access/firebase-auth"
import { FeatureSharedSignOutComponent } from "./feature-shared-sign-out.component"
import { FeatureSharedSignInComponent } from "./feature-shared-sign-in.component"
import { FeatureSharedLoginService } from "./feature-shared-login.service"
import { ProviderType } from "util/auth-provider"

@Component({
  imports: [
    UiInheritDirective,
    FeatureSharedSignOutComponent,
    FeatureSharedSignInComponent,
  ],
  standalone: true,
  template: `
    @if (!userInit()) {
      <p>Loading...</p>
    }

    @if (userInit()) {
      <div class="column-center">
        <div style="font-size: 36px; text-align: center">
          Welcome
        </div>
        @if (loggedIn()) {
          <lib-feature-shared-sign-out
            [afUserEmail]="afUserEmail()"
            [profile]="profile()"
            [providerId]="providerId()"
            (signOut)="signOut()"
          />
        }
        @if (loggedOut()) {
          <lib-feature-shared-sign-in
            [signInWithPassword]="signInWithPassword()"
            inherit
          />
        }

        <ng-content select="content"/>
        
      </div>
    }
  `,
  selector: 'lib-feature-shared-login',
})
export class FeatureSharedLoginComponent {
  private authDataAccessService = inject(DataAccessFirebaseAuthService)
  private profileDataAccessService = inject(DataAccessFirebaseAuthProfileService)
  private featureSharedLoginService = inject(FeatureSharedLoginService)

  providerTypes = input<ProviderType[]>([
    // ProviderType.APPLE,
    // ProviderType.FACEBOOK,
    ProviderType.GOOGLE,
    ProviderType.GITHUB,
    ProviderType.MICROSOFT,
    // ProviderType.TWITTER
    ProviderType.PASSWORD,
  ])

  signInWithPassword = this.featureSharedLoginService.withPassword_flag
  userInit = this.authDataAccessService.userInit
  afUserEmail = this.authDataAccessService.afUserEmail
  loggedIn = this.authDataAccessService.loggedIn
  loggedOut = computed(() => !this.authDataAccessService.loggedIn())
  providerId = this.authDataAccessService.providerId
  profile = this.profileDataAccessService.profile

  /**
   * connect to email verification or some other event to indicate first time member
   */
  newAccount = signal(false)

  signOut() {
    this.featureSharedLoginService.signOut()
  }

}
