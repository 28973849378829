import { Injectable, signal, computed } from "@angular/core"
import { faFontAwesome, faMoon, faSun } from "@fortawesome/pro-solid-svg-icons"
import { IconDefinition } from "@fortawesome/angular-fontawesome"

export enum ThemeMode {
  AUTO = "AUTO",
  DARK = "DARK",
  LIGHT = "LIGHT",
}

export interface Theme {
  name: string
  icon: IconDefinition
}

export const prefersDarkSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)")
export const prefersLightSchemeQuery = window.matchMedia("(prefers-color-scheme: light)")
export const prefersHighContrastQuery = window.matchMedia("(prefers-contrast: high)")
export const prefersReducedMotionQuery = window.matchMedia("(prefers-reduced-motion: reduce)")

@Injectable({
  providedIn: "root",
})
export class UiThemeService {
  selectedMode = signal<ThemeMode>(ThemeMode.AUTO)
  themeNames = signal(this.defaultThemeNames())
  themeIcons = signal(this.defaultThemeIcons())

  themes = computed<Theme[]>(() =>
    this.themeNames().map((theme, index) => {
      return {
        name: theme,
        icon: this.themeIcons()[index] || faFontAwesome,
      }
    }),
  )
  selectedTheme = signal(this.themes()[0])

  setThemeNames(themeNames: string[]) {
    this.themeNames.set(themeNames)
  }

  setThemeIcons(themeIcons: IconDefinition[]) {
    this.themeIcons.set(themeIcons)
  }

  setSelectedTheme(selectedTheme: Theme) {
    if (this.themes().find(theme => theme.name === selectedTheme.name)) {
      switch (selectedTheme.name) {
        case "auto": // depends on 0: auto, 1: light, and 2: dark
          this.selectedTheme.set(
            prefersDarkSchemeQuery.matches
              ? { name: this.themes()[2].name, icon: this.themes()[0].icon }
              : { name: this.themes()[1].name, icon: this.themes()[0].icon },
          )
          break
        default:
          this.selectedTheme.set(selectedTheme)
      }
      localStorage.setItem("selectedTheme", selectedTheme.name)
    }
  }

  /**
   * see node_modules/daisyui/src/theming/themes.js
   */
  defaultThemeNames(): string[] {
    return [
      "light",
      "dark",
      "cupcake",
      "bumblebee",
      "emerald",
      "corporate",
      "synthwave",
      "retro",
      "cyberpunk",
      "valentine",
      "halloween",
      "garden",
      "forest",
      "aqua",
      "lofi",
      "pastel",
      "fantasy",
      "wireframe",
      "black",
      "luxury",
      "dracula",
      "cmyk",
      "autumn",
      "business",
      "acid",
      "lemonade",
      "night",
      "coffee",
      "winter",
      "dim",
      "nord",
      "sunset",
    ]
  }

  defaultThemeIcons(): IconDefinition[] {
    return [
      faSun,
      faMoon,
    ]
  }

}
