import { computed, Directive, ElementRef, inject, OnDestroy, Renderer2 } from "@angular/core"
import { filter, takeUntil, tap } from "rxjs/operators"
import { toObservable } from "@angular/core/rxjs-interop"
import { Subject } from "rxjs"

/**
 * Proof of Concept
 *
 * for any component that imports this directive
 * all div and p elements are scanned
 * any other element with selector is scanned
 *
 * scanning will read all applied styles and classNames
 * then will assign those styles
 * along with all other default styles defined here
 *
 * TODO: apply all styles for each className, atm defaults override className styles
 * if we have a default for any of the className styles, we need to apply the className style instead of the default
 * for example:
 * the className "row" has "display:flex" and "gap:10px"
 * our default might have "display:inline-block"
 * we would need to use "flex" instead of "inline-block" when applying the default style for "display"
 */

@Directive({
  selector: "[e2eSafeCss], div, p",
  standalone: true
})
export class SafeCssDirective implements OnDestroy {
  private elementRef = inject(ElementRef)
  private renderer = inject(Renderer2)
  private destroy$ = new Subject<void>()

  element = computed(() => this.elementRef.nativeElement as HTMLElement)

  constructor() {
    toObservable(this.element)
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        tap(element => {
          const tagName = element.tagName
          switch (tagName.toLowerCase()) {
            case "div": {
              this.debugToConsole(element)
              this.applyDefaultStyles(element, [
                "color: purple",
                "background-color: green",
                "font-size: 16px",
                "width: 50%"
              ])
              this.debugToConsole(element)
            }
              break
            case "p":
            default:
              break
          }
        })
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private applyDefaultStyles(element: HTMLElement, styles: string[]) {
    styles.forEach(styleString => {
      const [styleName, defaultStyle] = styleString.split(":").map(str => str.trim())
      /**
       * apply style from element if exists, otherwise apply default style
       */
      this.renderer.setStyle(element, styleName, element.style.getPropertyValue(styleName) || defaultStyle)
    })
  }

  private debugToConsole(element: HTMLElement) {
    const elementStyle = element.style
    const elementStyle_array = Array.from(elementStyle)
    elementStyle_array.forEach(style => {
      console.log(style + ": " + elementStyle.getPropertyValue(style))
    })
    console.log(element.className.split(" "))
  }

}
