import { inject, Injectable } from "@angular/core"
import { Router } from "@angular/router"

export enum LinkTargetType {
  SELF = "SELF",
  NEW = "NEW",
  MODAL = "MODAL",
  PHONE = "PHONE",
}

export type LinkTarget = {
  type: LinkTargetType
  label: string
  value: "_self" | "_blank" | "modal" | "tel"
}

@Injectable({
  providedIn: "root",
})
export class LinkService {
  private router = inject(Router)

/*
  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
  ) {
  }
*/


/*
  linkClick(link: Image): void {
    if (!this.globalState.get("editMode")) {

      /!*
            if (link.targetOption === "new") {
              this.window.open(link.targetPath, "external", "noreferrer")
            }
      *!/
      if (link.targetType === LinkTargetType.SELF) {
        this.router.navigate(["/" + link.targetPath])
          .then()
          .catch(error => {
            const event: ExceptionEvent = {
              eventName: EventName.EXCEPTION,
              eventParams: {
                class: this.constructor.name,
                function: "linkClick()",
                action: "this.router.navigate([\"/\" + link.targetPath])",
                description: String(error),
                stack: error.stack,
                fatal: false
              }
            }
            this.analyticsService.sendEvent(event)
          })
      }
      /!*
            if (link.targetOption === "modal") {

            }
            if (link.targetOption === "phone") {
              this.window.location.href = "tel://" + link.targetPath
            }
      *!/
    }
  }
*/

  linkTarget(type: LinkTargetType): LinkTarget | undefined {
    return this.linkTargets.find(linkTarget => linkTarget.type === type)
  }

  get linkTargets(): LinkTarget[] {
    return [
      {
        label: "Same",
        type: LinkTargetType.SELF,
        value: "_self",
      },
      {
        label: "New",
        type: LinkTargetType.NEW,
        value: "_blank",
      },
      {
        label: "Modal",
        type: LinkTargetType.MODAL,
        value: "modal",
      },
      {
        label: "Phone",
        type: LinkTargetType.PHONE,
        value: "tel",
      },
    ]
  }

}
