import { ApplicationRef, Injectable } from "@angular/core"
// import { SwUpdate, VersionReadyEvent } from "@angular/service-worker"
// import { interval } from "rxjs"
// import { first, switchMap } from "rxjs/operators"
// import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore"

type Version = {
  major: number
  minor: number
  patch: number
  hash: string
}

@Injectable()
export class UpdateService {
/*
  version: Version = {
    major: 0,
    minor: 0,
    patch: 0,
    hash: "0"
  }
  private metaDataDocRef: AngularFirestoreDocument
  // private metaDataDoc$: Observable<MetaData>

  constructor(
    private afs: AngularFirestore,
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate
  ) {
    this.metaDataDocRef = this.afs
      .collection("documents")
      .doc("meta-data")
    this.metaDataDocRef
      .valueChanges()
      .subscribe(metaData => {
        if (metaData) {
          this.version = metaData["version"]
        }
      })

    applicationRef.isStable
      .pipe(
        first(stable => stable),
        switchMap(() => interval(10 * 1000)) // checking every 10 seconds
      )
      .subscribe((number) => {
        swUpdate.checkForUpdate().then()
        if (Number.isSafeInteger(number / 30)) {
          // console.log(number)
        }
      })

    applicationRef.isStable
      .pipe(
        first(stable => stable),
      )
      .subscribe(() => {
        swUpdate.checkForUpdate()
          .then(updateReady => {
            if (updateReady) {
              console.log("updateReady")
              //
            }
        })
        swUpdate.versionUpdates
          .subscribe(async (event) => {
            switch (event.type) {
              case "NO_NEW_VERSION_DETECTED":
                console.log("NO_NEW_VERSION_DETECTED")
                break
              case "VERSION_DETECTED":
                /!**
                 * downloading new version of the app
                 * TODO: add popup telling the user that this is happening
                 *!/
                console.log("downloading new version of the app")
                break
              case "VERSION_INSTALLATION_FAILED":
                break
              case "VERSION_READY":
                console.log("VERSION_READY")
                await this.incrementVersion(event)
                this.swUpdate.activateUpdate()
                  .then(activated => {
                    if (activated) {
                      console.log("activated")
                      document.location.reload()
                    }
                  })
                break
            }
          })
        swUpdate.unrecoverable
          .subscribe((event) => {
            alert(
              `An error occurred that we cannot recover from:\n${ event.reason }\n\n` +
              "Please reload the page.")
          })
      })

  }

  incrementVersion(event: VersionReadyEvent): void {
    console.log(event)
    if (event.latestVersion.hash !== this.version.hash) {
      const date = new Date()
      const major = parseInt(date.getFullYear().toString().substring(2), 10) // 2 digit year
      const minor = date.getMonth() + 1 // month number, from 1 to 12
      let patch = 0 // incremented, resets to 0 monthly
      if (this.version.major === major && this.version.minor === minor && this.version.patch >= 0) {
        patch = this.version.patch + 1
      }
      const version: Version = {
        major: major,
        minor: minor,
        patch: patch,
        hash: event.latestVersion.hash
      }
      this.metaDataDocRef
        .set({ version }, { merge: true })
        .then()
    }
  }
*/

}
