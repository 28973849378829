import { Component, computed, input, output } from "@angular/core"
import { SkeletonModule } from "primeng/skeleton"
import { Profile } from "data-access/firebase-auth"
import { ProviderType } from "util/auth-provider"

@Component({
  imports: [
    SkeletonModule,
  ],
  standalone: true,
  template: `
    @if (!userName()) {
      <p-skeleton
        width="5rem"
        height="2rem"
      />
    }
    <div>
      {{ userName() }}
    </div>
<!--
    <div>
      You are logged in with your {{ loginProviderAccountText() }}
    </div>
    @if (loginProviderEmailText()) {
      <div>
        {{ loginProviderEmailText() }}
      </div>
    }
-->
    <div class="center">
      <button
        class="btn"
        (click)="signOut.emit()"
      >
        Log Out
      </button>
    </div>
  `,
  selector: "lib-feature-shared-sign-out",
})
export class FeatureSharedSignOutComponent {
  profile = input.required<Profile | undefined>()
  providerId = input.required<ProviderType | undefined>()
  afUserEmail = input.required<string>()

  signOut = output<void>()

  userName = computed(() => this.profile()?.userName || "")
  loginProviderEmailText = computed(() => {
    switch (this.providerId()) {
      case "password":
        return ""
      case "google.com":
      case "microsoft.com":
      case "github.com":
        return "using " + this.afUserEmail()
    }
    return ""
  })
  loginProviderAccountText = computed(() => {
    switch (this.providerId()) {
      case "password":
        return "Email/Password"
      case "google.com":
        return "Google account"
      case "microsoft.com":
        return "Microsoft account"
      case "github.com":
        return "GitHub account"
    }
    return ""
  })

}
