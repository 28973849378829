import { Component, computed, ElementRef, input, OnInit, output, viewChild } from "@angular/core"
import { faClose } from "@fortawesome/pro-solid-svg-icons"
import { UiIconComponent } from "ui/icon"
import { ReactiveFormsModule } from "@angular/forms"

@Component({
  imports: [
    UiIconComponent,
    ReactiveFormsModule,
  ],
  standalone: true,
  template: `
    <dialog
      #modal
      [open]="true"
      class="modal"
      [class]="classes()"
      [style.align-items]="alignment()"
      (close)="cancelAndClose.emit()"
      (keydown.enter)="$event.preventDefault()"
    >
      <div
        class="modal-box column relative gap-2 py-0"
        style="max-width: 900px"
        [style.top.px]="this.offsetTop()"
      >
        <div class="column content-wrapper p-2">
          <div class="row-nowrap items-start">
            <div
              class="font-bold text-lg w-full"
              [class]="alignTitle() === 'center' ? 'center' : 'left'"
            >
              <ng-content select="title"></ng-content>
            </div>

            <button
              class="btn btn-sm text-lg btn-circle btn-ghost text-primary"
              (click)="cancelAndClose.emit()"
            >
              <lib-ui-icon [icon]="faClose"/>
            </button>
          </div>

          <ng-content select="content"></ng-content>
          <ng-content select="footer"></ng-content>

        </div>
      </div>

      <div
        class="modal-backdrop"
        (click)="cancelAndClose.emit()"
      ></div>
    </dialog>
  `,
  selector: "lib-ui-dialog",
})
export class UiDialogComponent implements OnInit {
  modalRef = viewChild.required<ElementRef<HTMLDialogElement>>("modal")
  position = input<"center" | "top" | "bottom">("center")
  offset = input<number>(0)
  scrollableBackground = input(false)
  alignTitle = input<"center" | "left">("center")

  cancelAndClose = output()

  classes = computed(() => {
    const classes: Set<string> = new Set
    if (this.scrollableBackground()) {
      classes.add("scrollable-background")
    }
    return Array.from(classes.values()).join(" ")
  })

  alignment = computed(() => {
    switch (this.position()) {
      case "center":
        return "center"
      case "top":
        return "flex-start"
      case "bottom":
        return "flex-end"
    }
  })

  offsetTop = computed(() => {
    switch (this.position()) {
      case "top":
        return this.offset()
      case "bottom":
        return -this.offset()
      default:
        return undefined
    }
  })

  ngOnInit() {
    this.modalRef().nativeElement.showModal()
  }

  protected readonly faClose = faClose
}

