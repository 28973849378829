import { Component } from "@angular/core"
import { BrandImageComponent } from "./brand-image.component"

@Component({
  imports: [
    BrandImageComponent,
  ],
  standalone: true,
  template: `
    <div class="column" style="gap: 50px; align-items: center; padding-top: 40px">

      <h2 class="max-w-full text-2xl font-bold">
        Major brands that we carry
      </h2>
      <div class="row gap-x-24 justify-center">
        @for (image of majorBrandImages(); track image.fileName) {
          <e2e-brand-image [image]="image"/>
        }
      </div>

    </div>
  `,
  selector: "e2e-brands",
})
export class BrandsComponent {

  majorBrandImages(): { exactMatch?: string, width: number, height: number, aspectRatio: number, alt: string, fileName: string }[] {
    return [
      { width: 300, height: 300, aspectRatio: 1, alt: "Bellota", fileName: "assets/footer/bellota.jpg" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Bloom Forge", fileName: "assets/footer/bloom-forge.jpg", exactMatch: "true" },
      { width: 300, height: 156, aspectRatio: 1.92, alt: "Capewell", fileName: "assets/footer/capewell.png" },
      { width: 300, height: 132, aspectRatio: 2.27, alt: "Castle Plastics", fileName: "assets/footer/castle-plastics.png", exactMatch: "true" },
      { width: 300, height: 38, aspectRatio: 7.89, alt: "EDSS DIM", fileName: "assets/footer/edss-hoofcare-products.png", exactMatch: "true" },
      { width: 300, height: 147, aspectRatio: 2.04, alt: "Flatland Forge", fileName: "assets/footer/flatland-forge.png", exactMatch: "true" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Footpro", fileName: "assets/footer/foot-pro.jpg" },
      { width: 300, height: 83, aspectRatio: 3.61, alt: "GE Forge", fileName: "assets/footer/ge-forge-and-tool.png", exactMatch: "true" },
      { width: 300, height: 200, aspectRatio: 1.5, alt: "Glu Shu", fileName: "assets/footer/glu-shu.png", exactMatch: "true" },
      { width: 300, height: 281, aspectRatio: 1.07, alt: "Grand Circuit", fileName: "assets/footer/grand-circuit.png", exactMatch: "true" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Heller International", fileName: "assets/footer/heller-international.png" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Hoof Jack", fileName: "assets/footer/hoof-jack.jpg", exactMatch: "true" },
      { width: 300, height: 332, aspectRatio: .9, alt: "Kahn Forge", fileName: "assets/footer/kahn-forge.png", exactMatch: "true" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Kerckhaert", fileName: "assets/footer/kerckhaert.jpg" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Liberty", fileName: "assets/footer/liberty.jpg" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Mustad", fileName: "assets/footer/mustad.png" },
      { width: 300, height: 435, aspectRatio: .69, alt: "NC Tool", fileName: "assets/footer/nc-tools.png", exactMatch: "true" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Plexus", fileName: "assets/footer/plexus.jpg" },
      { width: 300, height: 171, aspectRatio: 1.75, alt: "Rate Hoof Packing", fileName: "assets/footer/rate-hoof-packing.png", exactMatch: "true" },
      { width: 300, height: 160, aspectRatio: 1.88, alt: "Scott Anvils", fileName: "assets/footer/scott-anvils.png", exactMatch: "true" },
      { width: 300, height: 66, aspectRatio: 4.55, alt: "St. Croix Forge", fileName: "assets/footer/st-croix-forge.png", exactMatch: "true" },
      { width: 300, height: 300, aspectRatio: 1, alt: "Vettec", fileName: "assets/footer/vettec.jpg" },
      { width: 300, height: 373, aspectRatio: .8, alt: "Werkman", fileName: "assets/footer/werkman-hoofcare.png" },
    ]
  }

}
