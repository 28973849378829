import { Component, computed, effect, ElementRef, inject, viewChild } from "@angular/core"
import { GroupsComponent } from "../products/groups/groups.component"
import { ItemsComponent } from "../products/items.component"
import { GroupsService } from "../products/groups/groups.service"
import { BreadcrumbComponent } from "../breadcrumb.component"
import { RouterOutlet } from "@angular/router"
import { scrollIntoView, scrollToTop } from "util/scroll-to"
import { SearchComponent } from "../search.component"
import { UiInheritDirective } from "ui/inherit"
import { UtilRouteListenerService } from "util/route-listener"
import { BrandsComponent } from "../brands.component"
import { SupportComponent } from "../support.component"
import { CategoriesComponent } from "../products/categories.component"
import { AppService } from "../../app.service"

@Component({
  imports: [
    GroupsComponent,
    ItemsComponent,
    BreadcrumbComponent,
    RouterOutlet,
    UiInheritDirective,
    SearchComponent,
    BrandsComponent,
    SupportComponent,
    CategoriesComponent,
  ],
  standalone: true,
  template: `
    <div class="column" style="gap: 40px">
      <e2e-categories/>

      <div #SearchElement class="row-center" style="scroll-margin-top: 75px">
        <e2e-breadcrumb/>
        <e2e-search [searchInput]="lastBreadcrumb().label"/>
      </div>

      <div class="row" style="flex-wrap: nowrap; align-items: flex-start">
        <div class="column">
          @if (!groupItems().length && groupsView().length) {
            <e2e-groups [groupsView]="groupsView()"/>
          }
          @if (groupItems().length && group(); as group) {
            <e2e-items
              [group]="group"
              [groupItems]="groupItems()"
            />
          }

          <e2e-brands/>

          <e2e-support/>
          
        </div>
      </div>
    </div>
  `,
  selector: "e2e-products-page",
})
export class ProductsComponent {
  private groupService = inject(GroupsService)
  private routeService = inject(UtilRouteListenerService)
  private appService = inject(AppService)

  searchTerm = this.appService.searchTerm
  lastBreadcrumb = this.groupService.lastBreadcrumb

  searchElementRef = viewChild.required<ElementRef<HTMLElement>>("SearchElement")
  searchElement = computed(() => this.searchElementRef().nativeElement)

  group = this.groupService.group
  groupItems = computed(() =>
    this.groupService.groupItemsSorted()
      .filter(item => {
        // console.log(item)
        return true
      })
  )
  groupsView = computed(() =>
    this.groupService.groupsViewSorted()
      .filter(item => {
        return true
      })
  )

  constructor() {
    // console.log(this.constructor.name)
    effect(() => {
      if (this.routeService.previousUrl().includes("/products")) {
        scrollIntoView(this.searchElement(), "smooth", "start", "start")
      }
      else {
        scrollToTop("smooth", 0)
      }
    })
  }

}
