import { inject, Pipe, PipeTransform } from "@angular/core"
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeStyle, SafeUrl } from "@angular/platform-browser"

@Pipe({
  name: "sanitize",
  standalone: true,
})
export class FileNamePipe implements PipeTransform {
  private sanitizer = inject(DomSanitizer)

  transform(value: any, type: "html" | "style" | "url" | "resourceUrl"): SafeHtml | SafeStyle | SafeUrl | SafeResourceUrl {
    switch (type) {
      case "html":
        return this.sanitizer.bypassSecurityTrustHtml(value)
      case "resourceUrl":
        return this.sanitizer.bypassSecurityTrustResourceUrl(value)
      case "style":
        return this.sanitizer.bypassSecurityTrustStyle(value)
      case "url":
        return this.sanitizer.bypassSecurityTrustUrl(value)
    }

  }

}
