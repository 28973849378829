import { Component, input } from "@angular/core"
import { GroupCardComponent } from "./group-card.component"
import { RouterLink } from "@angular/router"
import { UiInheritDirective } from "ui/inherit"
import { GroupView } from "./groups.service"

@Component({
  imports: [
    GroupCardComponent,
    RouterLink,
    UiInheritDirective,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="column-center wide">
      <div class="row" style="justify-content: center; gap: 5rem">
        @for (groupView of groupsView(); track index; let index = $index) {
          <div style="width: 266px; aspect-ratio: 1.9">
            <e2e-group-card [groupView]="groupView" inherit/>
          </div>
        }
      </div>
    </div>
  `,
  selector: "e2e-groups",
})
export class GroupsComponent {
  groupsView = input.required<GroupView[]>()
}
