import { Directive, ElementRef, HostListener, inject, OnInit } from "@angular/core"

@Directive({
  standalone: true,
  selector: "[libTextareaAutoresize]"
})
export class TextareaAutoresizeDirective implements OnInit {
  private elementRef = inject(ElementRef)

  @HostListener(":input")
  onInput() {
    this.resize()
  }

  ngOnInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize())
    }
  }

  resize() {
    this.elementRef.nativeElement.style.height = "0"
    this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + "px"
  }
}
