import { Directive, ElementRef, inject, OnInit } from "@angular/core"

@Directive({
  selector: '[libAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements OnInit {
  private elementRef = inject(ElementRef)

  ngOnInit(): void {
    this.elementRef.nativeElement.focus()
  }
}
