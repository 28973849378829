import { Component } from "@angular/core"

@Component({
  imports: [],
  standalone: true,
  template: ``,
  selector: "lib-ui-inherit",
})
export class UiInheritComponent {
}
