import { computed, effect, inject, Injectable, signal } from "@angular/core"
import { BreakpointObserver, Breakpoints, MediaMatcher } from "@angular/cdk/layout"
import { toSignal } from "@angular/core/rxjs-interop"

@Injectable({
  providedIn: "root"
})
export class UtilBreakpointsService {
  private breakpointObserver = inject(BreakpointObserver)
  private mediaMatcher = inject(MediaMatcher)

  breakpointsXSmall = toSignal(this.breakpointObserver.observe([Breakpoints.XSmall]))
  breakpointsSmall = toSignal(this.breakpointObserver.observe([Breakpoints.Small]))
  breakpointsMedium = toSignal(this.breakpointObserver.observe([Breakpoints.Medium]))
  breakpointsLarge = toSignal(this.breakpointObserver.observe([Breakpoints.Large]))
  breakpointsXLarge = toSignal(this.breakpointObserver.observe([Breakpoints.XLarge]))
  breakpointsHandset = toSignal(this.breakpointObserver.observe([Breakpoints.Handset]))
  breakpointsTablet = toSignal(this.breakpointObserver.observe([Breakpoints.Tablet]))
  breakpointsWeb = toSignal(this.breakpointObserver.observe([Breakpoints.Web]))
  breakpointsHandsetPortrait = toSignal(this.breakpointObserver.observe([Breakpoints.HandsetPortrait]))
  breakpointsTabletPortrait = toSignal(this.breakpointObserver.observe([Breakpoints.TabletPortrait]))
  breakpointsWebPortrait = toSignal(this.breakpointObserver.observe([Breakpoints.WebPortrait]))
  breakpointsHandsetLandscape = toSignal(this.breakpointObserver.observe([Breakpoints.HandsetLandscape]))
  breakpointsTabletLandscape = toSignal(this.breakpointObserver.observe([Breakpoints.TabletLandscape]))
  breakpointsWebLandscape = toSignal(this.breakpointObserver.observe([Breakpoints.WebLandscape]))

  breakpointMatches = computed(() => ({
    XSmall: this.breakpointsXSmall()?.matches,
    Small: this.breakpointsSmall()?.matches,
    Medium: this.breakpointsMedium()?.matches,
    Large: this.breakpointsLarge()?.matches,
    XLarge: this.breakpointsXLarge()?.matches,
    Handset: this.breakpointsHandset()?.matches,
    Tablet: this.breakpointsTablet()?.matches,
    Web: this.breakpointsWeb()?.matches,
    HandsetPortrait: this.breakpointsHandsetPortrait()?.matches,
    TabletPortrait: this.breakpointsTabletPortrait()?.matches,
    WebPortrait: this.breakpointsWebPortrait()?.matches,
    HandsetLandscape: this.breakpointsHandsetLandscape()?.matches,
    TabletLandscape: this.breakpointsTabletLandscape()?.matches,
    WebLandscape: this.breakpointsWebLandscape()?.matches,
  }))

  currentBreakpoint = computed(() =>
    this.breakpointMatches().XSmall
      ? Breakpoints.XSmall
      : this.breakpointMatches().Small
        ? Breakpoints.Small
        : this.breakpointMatches().Medium
          ? Breakpoints.Medium
          : this.breakpointMatches().Large
            ? Breakpoints.Large
            : this.breakpointMatches().XLarge
              ? Breakpoints.XLarge : Breakpoints.XLarge
  )

  debug = signal(false)

  constructor() {
    effect(() => {
      if (this.debug()) if (this.debug()) console.log("XSmall", this.breakpointsXSmall()?.matches)
    })
    effect(() => {
      if (this.debug()) if (this.debug()) console.log("Small", this.breakpointsSmall()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("Medium", this.breakpointsMedium()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("Large", this.breakpointsLarge()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("XLarge", this.breakpointsXLarge()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("Handset", this.breakpointsHandset()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("Tablet", this.breakpointsTablet()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("Web", this.breakpointsWeb()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("HandsetPortrait", this.breakpointsHandsetPortrait()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("TabletPortrait", this.breakpointsTabletPortrait()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("WebPortrait", this.breakpointsWebPortrait()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("HandsetLandscape", this.breakpointsHandsetLandscape()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("TabletLandscape", this.breakpointsTabletLandscape()?.matches)
    })
    effect(() => {
      if (this.debug()) console.log("WebLandscape", this.breakpointsWebLandscape()?.matches)
    })
  }


}
