import { Component, computed, effect, inject, input, signal, untracked } from "@angular/core"
import { animate, keyframes, state, style, transition, trigger } from "@angular/animations"
import { debounceSignal } from "@ddtmm/angular-signal-generators"
import { UtilEnvironmentService } from "util/environment"

export enum JiggleState {
  NONE = "NONE",
  JIGGLE = "JIGGLE",
  REPEAT = "REPEAT",
}

@Component({
  imports: [],
  standalone: true,
  animations: [
    trigger("jiggle", [
      state(JiggleState.NONE, style({ transform: "rotate(0)" })),
      state(JiggleState.JIGGLE, style({ transform: "rotate(0)" })),
      state(JiggleState.REPEAT, style({ transform: "rotate(0)" })),
      transition("NONE => JIGGLE, JIGGLE => REPEAT, REPEAT => JIGGLE", [
        animate(
          "2s",
          keyframes([
            style({ transform: "rotate(0)", offset: 0 }),
            style({ transform: "rotate(6deg)", offset: 0.1 }),
            style({ transform: "rotate(8deg)", offset: 0.25 }),
            style({ transform: "rotate(6deg)", offset: 0.4 }),
            // style({ transform: "rotate(0)", offset: 0.5 }),
            style({ transform: "rotate(-6deg)", offset: 0.6 }),
            style({ transform: "rotate(-8deg)", offset: 0.75 }),
            style({ transform: "rotate(-6deg)", offset: 0.9 }),
            style({ transform: "rotate(0)", offset: 1.0 }),
          ]),
        ),
      ]),
    ]),
  ],
  template: `
    <div [style.transform]="transform()">
      <div
        (@jiggle.done)="onAnimationDone($event)"
        [@jiggle]="jiggleState()"
        class="column"
        style="box-shadow: 0 0 15px rgba(255,255,255,1); transform-origin: center -3px; justify-content: center; align-items: center"
        [style.background-image]="redPaperTexture()"
        [style.height.px]="styles().height"
        [style.width.px]="styles().width"
        [style.padding-top.px]="styles().paddingTop"
        [style.clip-path]="path()"
        [style.opacity]="styles().opacity"
      >
        <div
          style="color: white"
          [style.font-size.px]="styles().fontSize"
        >
          {{ count() }}
        </div>
      </div>
    </div>
  `,
  selector: "lib-bookmark",
})
export class BookmarkComponent {
  private environmentService = inject(UtilEnvironmentService)

  mouseEventType = input<string | undefined>(undefined)
  count = input<number>(0)
  context = input<"timeline" | "pageTopBar">("timeline")
  rotation = input<number | undefined>(undefined)


  mouseEventType_debounced = debounceSignal<string>("", 100)
  jiggleState = signal<JiggleState>(JiggleState.NONE)

  redPaperTexture = signal(this.environmentService.getEnvironment().imagekit_urlEndpoint + "/assets/images/red-paper-texture.png")

  path = computed(() => {
    return "polygon(0% 20%, 30% 0%, 70% 0%, 100% 20%, 100% 100%, 0% 100%)"
  })

  transform = computed(() => {
    const rotation = this.rotation()
    if (rotation !== undefined) {
      const reverseRotation = rotation * -1
      return "rotate(" + reverseRotation.toString() + "deg)"
    }
    return
  })

  styles = computed(() => {
    if (this.context() === "pageTopBar") {
      return {
        fontSize: 12, // this.count() < 10 ? 12 : this.count() < 100 ? 10 : 8,
        height: 24,
        width: 20,
        paddingTop: 4,
        backgroundColor: undefined,
        backgroundPadding: undefined,
        opacity: 1,
      }
    }
    return {
      fontSize: this.count() < 10 ? 6 : this.count() < 100 ? 5 : 4,
      height: 12,
      width: 8,
      paddingTop: 2,
      backgroundColor: undefined,
      backgroundPadding: undefined,
      opacity: this.count() ? 1 : 0,
    }
  })

  constructor() {
    effect(() => {
      const eventType = this.mouseEventType()
      untracked(() => {
        switch (eventType) {
          case "mouseover":
          case "mouseout":
            this.mouseEventType_debounced.set(eventType)
            break
        }
      })
    })
    effect(() => {
      const eventType = this.mouseEventType_debounced()
      untracked(() => {
        switch (eventType) {
          case "mouseover":
            this.startJiggleLoop()
            break
          case "mouseout":
            this.stopJiggleLoop()
            break
        }
      })
    })
  }

  onAnimationDone(event: any) {
    if (this.jiggleState() !== JiggleState.NONE) {
      this.startJiggleLoop()
    }
  }

  startJiggleLoop() {
    this.jiggleState.update((jiggleState) =>
      jiggleState === JiggleState.JIGGLE ? JiggleState.REPEAT : JiggleState.JIGGLE,
    )
  }

  stopJiggleLoop() {
    this.jiggleState.set(JiggleState.NONE)
  }
}
