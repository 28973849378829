import { computed, effect, inject, Injectable, signal, untracked } from "@angular/core"
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router"
import { toSignal } from "@angular/core/rxjs-interop"

/**
 * these values are used by the router
 */
interface QueryParams extends Params {
  debug?: string
  exactMatch?: string
  onlyCategories?: string
  onlyProducts?: string
}

@Injectable({
  providedIn: "root"
})
export class UtilRouteListenerService {
  private router = inject(Router)
  private activatedRoute = inject(ActivatedRoute)

  // private _debug = signal(false)
  // readonly debug = this._debug.asReadonly()

  params = toSignal<Params>(this.activatedRoute.params)
  queryParams = toSignal<QueryParams>(this.activatedRoute.queryParams)
  debug = computed(() => {
    const queryParams: QueryParams = this.queryParams() || {}
    const debugFromStorage = localStorage.getItem("debug")
    const debug = queryParams.debug !== undefined
      ? queryParams.debug
      : debugFromStorage !== null
        ? debugFromStorage
        : undefined
    if (debug !== undefined) {
      localStorage.setItem("debug", debug)
    }
    return debug === "true" || debug === ""
  })

  private activatedRoute_fragment = toSignal(this.activatedRoute.fragment)
  routeFragments = computed(() => {
    const activatedRoute_fragment = this.activatedRoute_fragment()
    return activatedRoute_fragment ? activatedRoute_fragment.split("/") : []
  })

  private _pathSegments = signal<string[]>([])
  pathSegments = this._pathSegments.asReadonly()

  private _url = signal("")
  url = this._url.asReadonly()
  previousUrl = signal("")

  // private _routerEvent = signal<RouterEvent | undefined>(undefined)
  private routerEvent = toSignal(this.router.events)

  constructor() {
    // this.subscribeToRouterEvents()
    // this.subscribeToActivatedRoute_queryParams()

    effect(() => {
      const event_2 = this.routerEvent()
      const routerEvent = (event_2 instanceof NavigationEnd) && event_2 || undefined
      const activatedRouteFragment = this.activatedRoute_fragment()
      untracked(() => {
        this.onRouteChange(routerEvent, activatedRouteFragment)
      })
    })
  }

  /*
    subscribeToActivatedRoute_queryParams() {
      return this.activatedRoute.queryParams
        .subscribe((queryParams: QueryParams) => {
          const debugFromStorage = localStorage.getItem("debug")
          const debug = queryParams.debug !== undefined
            ? queryParams.debug
            : debugFromStorage !== null
              ? debugFromStorage
              : undefined
          if (debug !== undefined) {
            this._debug.set(debug === "true" || debug === "")
            localStorage.setItem("debug", debug)
          }
        })
    }
  */

  changeFragment(fragment: string) {
    this.changeRoute([], [], fragment)
  }

  removeFragment() {
    this.changeFragment("")
  }

  changeRoute(route: string[], queryParams: Params = [], fragment?: string) {
    if (fragment) {
      this.router
        .navigate(route, { queryParams, fragment })
        .then()
    }
    if (!fragment) {
      this.router
        .navigate(route, { queryParams })
        .then()
    }
  }

  onRouteChange(routerEvent: NavigationEnd | undefined, activatedRouteFragment: string | null | undefined) {
    if (routerEvent) {

      let root = this.router.routerState.snapshot.root
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0]
        } else {
          // this._routeHasPageToolbar.set(root.data && root.data["routeHasPageToolbar"])
          break
        }
      }

      if (location.pathname === "/local-maps" && !location.hash) {
        // this.changeFragment(this.localMapsMode().toLowerCase())
      }

      this.previousUrl.set(this.url())
      this._url.set(routerEvent.url)

      const hostnameArray = location.hostname.split(".")

    }

    const pathSegments = location.pathname
      .replace(/^\//, "")
      .toLowerCase()
      .split("/")
      .filter(segment => !!segment)
      .map(segment => decodeURIComponent(segment))
    this._pathSegments.set(pathSegments)
  }

}
