import { Component, computed, input, signal } from "@angular/core"
import { faEclipse, faMoon, faSun } from "@fortawesome/pro-solid-svg-icons"
import { FormsModule } from "@angular/forms"
import { UiIconComponent } from "ui/icon"
import { ThemeMode } from "./ui-theme.service"

@Component({
  imports: [
    UiIconComponent,
    FormsModule,
  ],
  standalone: true,
  template: `
    <label
      class="swap center w-8 text-2xl"
    >
      <input
        type="radio"
        class="theme-controller"
        [value]="mode()"
        (click)="switchMode()"
      >
      @if (autoMode()) {
        <lib-ui-icon [icon]="faEclipse"/>
      }
      @if (darkMode()) {
        <lib-ui-icon [icon]="faMoon"/>
      }
      @if (lightMode()) {
        <lib-ui-icon [icon]="faSun"/>
      }
    </label>
<!--
    <div
      class="center w-8 text-2xl"
      (click)="switchMode()"
    >
      @if (autoMode()) {
        <lib-ui-icon [icon]="faEclipse"/>
      }
      @if (darkMode()) {
        <lib-ui-icon [icon]="faMoon"/>
      }
      @if (lightMode()) {
        <lib-ui-icon [icon]="faSun"/>
      }
    </div>
-->
  `,
  selector: "lib-ui-theme-dark-mode-switcher",
})
export class UiThemeDarkModeSwitcherComponent {

  mode = signal<ThemeMode>(ThemeMode.AUTO)
  autoMode = computed(() => this.mode() === ThemeMode.AUTO)
  darkMode = computed(() => this.mode() === ThemeMode.DARK)
  lightMode = computed(() => this.mode() === ThemeMode.LIGHT)

  darkModeTheme = input.required<string>()

  constructor() {
    const mode: ThemeMode = localStorage.getItem("mode") as ThemeMode
    switch (mode) {
      case ThemeMode.AUTO:
      case ThemeMode.DARK:
      case ThemeMode.LIGHT:
        this.mode.set(mode)
    }
  }

  switchMode() {
    const mode_map = new Map<ThemeMode, ThemeMode>([
      [ThemeMode.AUTO, ThemeMode.LIGHT],
      [ThemeMode.LIGHT, ThemeMode.DARK],
      [ThemeMode.DARK, ThemeMode.AUTO],
    ])
    const mode = mode_map.get(this.mode()) || ThemeMode.AUTO
    this.mode.set(mode)
    localStorage.setItem("mode", mode)
  }

  protected readonly faMoon = faMoon
  protected readonly faSun = faSun
  protected readonly faEclipse = faEclipse
}
