import { APP_INITIALIZER, Injectable, InjectionToken } from "@angular/core"
import { Observable } from "rxjs"

interface Environment {
  production: boolean
  storageBucket: string
  baseUrl: string
  imagekit_urlEndpoint?: string
}

@Injectable({
  providedIn: "root",
})
export class UtilEnvironmentService {
  private environment: Environment = {} as Environment

  static environmentProvider: {
    provide: InjectionToken<readonly (() => (void | Observable<unknown> | Promise<unknown>))[]>
    multi: boolean
    deps: typeof UtilEnvironmentService[]
  } = {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [UtilEnvironmentService],
  }

  setEnvironment(environment: Environment): void {
    this.environment = environment
  }

  getEnvironment(): Environment {
    return this.environment
  }

}
