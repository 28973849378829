import { Component, inject } from "@angular/core"
import { CheckoutService, CheckoutSessionLineItem } from "./checkout.service"
import { AsyncPipe, CurrencyPipe, JsonPipe } from "@angular/common"
import { UiIconComponent } from "ui/icon"
import { faTrash } from "@fortawesome/pro-regular-svg-icons"

@Component({
  imports: [
    AsyncPipe,
    JsonPipe,
    CurrencyPipe,
    UiIconComponent,
  ],
  standalone: true,
  template: `
    @for (lineItem of checkoutLineItems(); track lineItem.price_data.product_data.metadata.itemSku; let index = $index; ) {
      <div class="row items-start">
        <div>
          {{ index + 1 }}.
        </div>
        <button 
          class="btn btn-accent btn-sm text-white"
          (click)="removeItem(index, checkoutLineItems())"
        >
          <lib-ui-icon [icon]="faTrash"/>
        </button>
        <div>
          <div>
            {{ lineItem.price_data.product_data.name }}
          </div>
          <div>
            {{ lineItem.price_data.product_data.description }}
          </div>
          <div>
            {{ lineItem.quantity }} x {{ lineItem.price_data.unit_amount / 100 | currency }}
          </div>
        </div>
      </div>
    }
  `,
  selector: "e2e-cart",
})
export class CartComponent {
  private cartService = inject(CheckoutService)

  checkoutLineItems = this.cartService.checkoutLineItems

  removeItem(lineItemIndex: number, checkoutLineItems: CheckoutSessionLineItem[]) {
    this.cartService.removeItem(lineItemIndex, checkoutLineItems)
  }

  protected readonly faTrash = faTrash
}
