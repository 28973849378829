import { Component } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"
import { RouterLink } from "@angular/router"

@Component({
  imports: [
    NgOptimizedImage,
    RouterLink,
  ],
  standalone: true,
  template: `
    <h2 class="center text-3xl font-bold">
      Delivered right to your door!
    </h2>

    <div class="row-center gap-16 pt-10">
      <div>
        <img
          style="max-width: 200px"
          alt=""
          ngSrc="assets/ups-logo.jpg"
          height="200"
          width="200"
          priority=""
        />
      </div>
      <div>
        <img
          style="max-width: 200px"
          alt=""
          ngSrc="assets/speedee-logo.jpg"
          height="200"
          width="200"
          priority=""
        />
      </div>

    </div>
<!--
    <div>
      Your online shopping experience at Duggan Farrier Supplies is convenient, secure, and easy. Most orders in
      the
      Midwest ship via Spee-Dee Delivery Service and is next day delivery in most cases. U.P.S is also an option
      for
      delivery. Have questions on a product? Please call us at 763-427-5850 or email at robertduggan&#64;rtduggan.com.
      We will be more than happy to help.
    </div>
-->
  `,
  selector: "e2e-delivery",
})
export class DeliveryComponent {

}
