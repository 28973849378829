import { effect, inject, Injectable, signal, untracked } from "@angular/core"
import { UtilRouteListenerService } from "util/route-listener"

@Injectable({
  providedIn: "root",
})
export class AppService {
  private routeService = inject(UtilRouteListenerService)

  callUsElementIsInViewport = signal(true)
  searchTerm = signal("")

  constructor() {
    effect(() => {
      const url = this.routeService.url()
      untracked(() => {
        this.callUsElementIsInViewport.set(url === "/")
      })
    })
  }

}
