import { Component } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <div class="column" style="gap: 50px; align-items: center; padding-top: 40px">

      <h2 class="max-w-full text-2xl font-bold">
        We support the American Farrier's Association (AFA) and the World Championship Blacksmiths (WCB)
      </h2>
      <div class="row" style="column-gap: 100px; justify-content: center">
        @for (image of supportedImages(); track image.fileName) {
          <div
            style="max-width: 200px"
          >
            <img
              [alt]="image.alt"
              style="object-fit: cover"
              [style.aspect-ratio]="image.aspectRatio"
              priority=""
              [ngSrc]="image.fileName"
              [height]="image.height"
              [width]="image.width"
            >
          </div>
        }
      </div>

    </div>
  `,
  selector: "e2e-support",
})
export class SupportComponent {

  supportedImages(): { width: number, height: number, aspectRatio: number, alt: string, fileName: string }[] {
    return [
      {
        width: 300,
        height: 160,
        aspectRatio: 1.88,
        alt: "",
        fileName: "assets/footer/american-farriers-association.png",
      },
      {
        width: 300,
        height: 131,
        aspectRatio: 2.29,
        alt: "",
        fileName: "assets/footer/world-championship-blacksmiths.png",
      },
    ]
  }

}
