import { Component } from "@angular/core"

@Component({
  imports: [],
  standalone: true,
  template: `
    <p>
      util-environment works!
    </p>
  `,
  selector: "lib-util-environment",
})
export class UtilEnvironmentComponent {
}
