import { Component, computed, effect, inject, input, signal, untracked } from "@angular/core"
import { ItemComponent } from "./item.component"
import { FormsModule } from "@angular/forms"
import { convertToImageObject } from "../../utilities/convert-to-image-object.utility"
import { CheckoutComponent } from "../checkout/checkout.component"
import { GroupImage, GroupsService } from "./groups/groups.service"
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { CurrencyPipe, NgOptimizedImage } from "@angular/common"
import { CheckoutService } from "../checkout/checkout.service"
import { SliderComponent } from "./slider.component"
import { debounceSignal } from "@ddtmm/angular-signal-generators"
import { Item, Group } from "../import/content.service"
import { UiIconComponent } from "ui/icon"
import { UiInheritDirective } from "ui/inherit"

@Component({
  imports: [
    ItemComponent,
    FormsModule,
    UiInheritDirective,
    CheckoutComponent,
    UiIconComponent,
    NgOptimizedImage,
    SliderComponent,
    CurrencyPipe,
  ],
  providers: [],
  standalone: true,
  template: `
    <div class="row-center" style="align-items: flex-start; ">
      <div class="inline-block shadow-2 p-4 rounded" style="width: 350px; max-width: 100%">
        <div class="column" style="align-items: center; justify-content: center">
          <div
            style="text-align: center"
            (click)="resetCarousel()"
          >
            {{ selectedItem()?.title || group().title }}
          </div>

          <div class="row-center" style="width: 400px; height: 300px">
            <e2e-slider
              [imageObjects]="imageObjects()"
              [sliderIndex]="sliderIndex()"
              (changeImage)="selectItemByImage($event)"
              inherit
            />
          </div>

          <div class="bg-base-100" style="z-index: 3; padding: .2rem">
            {{ selectedItem()?.description }}
          </div>
        </div>
      </div>
      <div class="column-center" style="text-align: center; gap: 50px">
        <div class="column">
          @for (item of groupItems(); track item.sku) {
            <e2e-item
              [item]="item"
              [selected]="selectedItem()?.sku === item.sku"
              (click)="clickItem(item)"
              (mouseover)="mouseoverItem(item)"
              inherit
            />
          }
        </div>
        <div class="row">
          <div class="column">
            Quantity
            <div class="join">
              <button
                class="btn btn-bordered join-item"
                (click)="decreaseQuantity(quantity())"
              >
                <lib-ui-icon [icon]="faMinus" />
              </button>
              <div>
                <input
                  class="input input-bordered join-item"
                  type="tel"
                  style="width: 60px; text-align: center;"
                  [ngModel]="quantity()"
                  (ngModelChange)="quantityChanges($event)"
                />
              </div>
              <button
                class="btn btn-bordered join-item"
                (click)="increaseQuantity(quantity())"
              >
                <lib-ui-icon [icon]="faPlus" />
              </button>
            </div>
            <div>
              {{ price() | currency }}
            </div>
          </div>
          <div class="column">
            <button
              class="btn btn-success btn-sm"
              [disabled]="!quantity()"
              (click)="addToCart(selectedItem(), group(), quantity())"
            >
              Add To Cart
            </button>
            <e2e-checkout inherit/>
          </div>
        </div>
      </div>
    </div>
  `,
  selector: "e2e-items",
})
export class ItemsComponent {
  private groupService = inject(GroupsService)
  private cartService = inject(CheckoutService)

  // defaultImage = "https://storage.googleapis.com/" + environment.firebaseConfig.storageBucket + "/images/1420594609_132.jpg"

  groupItems = input.required<Item[]>()
  group = input.required<Group>()

  showAllImages = signal(true)
  selectedItem = signal<Item | undefined>(undefined)
  quantity = signal(1)
  price = computed(() => this.quantity() * (this.selectedItem()?.retail || 0))
  carouselIndex = signal(0)
  sliderIndex = signal(0)
  selectItemDelay = signal(0)
  selectItemDelayDebounced = debounceSignal(0, 2000)

  imageObjects = computed(
    () => {
      return (
          !this.showAllImages() &&
          this.groupItems().find((groupItem) => groupItem.sku === this.selectedItem()?.sku) &&
          this.selectedItem() &&
          this.selectedItem()?.images.map((image) => convertToImageObject(image))
        ) ||
        (
          this.groupService.groupImageObjects().length &&
          this.groupService.groupImageObjects()
        ) ||
        (
          [convertToImageObject("product-placeholder.svg", { source: "assets" })]
        )
    },
  )

  constructor() {
    effect(() => {
      if (this.selectItemDelayDebounced()) {
        untracked(() => {
          this.selectItemDelay.set(0)
          this.selectItemDelayDebounced.set(0)
        })
      }
    })
  }

  decreaseQuantity(quantity: number) {
    this.quantity.set(Math.max(quantity - 1, 1))
  }

  increaseQuantity(quantity: number) {
    this.quantity.set(Math.max(quantity + 1, 1))
  }

  quantityChanges(quantityInputChanges: string | number) {
    const quantity =
      typeof quantityInputChanges === "string" ? Number(quantityInputChanges.replace(/\D/g, "")) : quantityInputChanges
    this.quantity.set(Math.max(quantity, 1))
  }

  selectItemByImage(groupImage: GroupImage) {
    /**
     * find the item that has this image
     */
    const item = this.groupItems().find((groupItem) =>
      groupItem.images.find(image => convertToImageObject(image).src === groupImage.src))
    if (item) {
      this.selectItem(item)
    }
  }

  clickItem(item: Item) {
    // if (!this.selectItemDelay() && !this.selectItemDelayDebounced()) {
      this.selectItem(item)
      this.selectItemDelay.set(Date.now())
      this.selectItemDelayDebounced.set(Date.now())
    // }
  }

  mouseoverItem(item: Item) {
    if (!this.selectItemDelay()) {
      this.selectItem(item)
    }
  }

  selectItem(selectedItem: Item) {
    /**
     * find the image index that matches selectedItem.images[0]
     */
    const imageIndex = this.imageObjects().findIndex(image =>
      image.src === convertToImageObject(selectedItem.images[0]).src)
    this.sliderIndex.set(imageIndex)

    // this.showAllImages.set(false)
    if (!this.quantity()) {
      this.quantity.set(1)
    }
    if (selectedItem) {
      this.selectedItem.set(selectedItem)
      if (selectedItem.images.length) {
        // this.carouselIndex.set(0)
      }
    }
  }

  addToCart(selectedItem: Item | undefined, group: Group | undefined, quantity: number) {
    if (selectedItem && group && quantity) {

      this.cartService.add(selectedItem, group, quantity)
      // this.store.dispatch(CartActions.add({ item: selectedItem, group, quantity }))
    }
  }

/*
  onImageLoadError(groupImage: GroupImage) {
    /!**
     * Remove image from groupImages
     *
     * TODO: send error report to analytics
     console.log("missing image: ", groupImage.itemImageSrc)
     *!/
    this.groupImages = this.groupImages
      .filter(image => image.itemImageSrc !== groupImage.itemImageSrc)
    if (!this.groupImages.length) {
      this.groupImages = [convertToImageObject("product-placeholder.svg", { source: "assets" })]
    }
  }
*/

  resetCarousel() {
    this.carouselIndex.set(
      this.groupService.groupImageObjects().findIndex((item) => {
        const filePath = this.selectedItem()?.images[this.carouselIndex()]
        return filePath && item.src.includes(filePath)
      }),
    )
    this.showAllImages.set(true)
  }

  protected readonly faMinus = faMinus
  protected readonly faPlus = faPlus
}
