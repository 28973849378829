import { computed, inject, Injectable, signal, WritableSignal } from "@angular/core"
import { collection, doc, Firestore, onSnapshot, query, where } from "@angular/fire/firestore"
import { sort } from "util/sort"
import { dataTransform } from "../../utilities/data-transform.utility"
import { ContentDoc, ContentService, ContentType, Groups, Items } from "./content.service"
import { getBlob, ref, Storage } from "@angular/fire/storage"

export type MenuItem = {
  label: string
  routerLink: string
}

@Injectable({
  providedIn: "root",
})
export class FirestoreReadService {
  private firestore = inject(Firestore)
  private contentService = inject(ContentService)
  private storage = inject(Storage)

  private _menuItemsDocsLoaded = signal(false)
  private _itemsDocsLoaded = signal(false)
  private _groupsDocsLoaded = signal(false)

  menuItemsDocsLoaded = this._menuItemsDocsLoaded.asReadonly()
  itemsDocsLoaded = this._itemsDocsLoaded.asReadonly()
  groupsDocsLoaded = this._groupsDocsLoaded.asReadonly()

  private menuItemsDocs = signal<ContentDoc[]>([])
  private itemsDocs = signal<ContentDoc[]>([])
  private groupsDocs = signal<ContentDoc[]>([])

  menuItems_array = computed(() => this.menuItemsDocs()
    .flatMap(doc => Object.values(doc.content) as MenuItem[]))
  items_keyed = signal<Items>({})
  groups_keyed = signal<Groups>({})

  items_map = computed(() => new Map(Object.entries(this.items_keyed())))
  groups_map = computed(() => new Map(Object.entries(this.groups_keyed())))
  items_array = computed(() => Object.values(this.items_keyed())
    .sort((a, b) => sort(a.title, b.title)),
  )
  groups_array = computed(() => Object.values(this.groups_keyed())
    .sort((a, b) => sort(a.title, b.title)),
  )

  constructor() {
    // this.subscribeToSpreadsheetFilename()
    // this.subscribeToJsonFileRef()
    /*
        this.subscribeToContent(
          ContentType.MENU,
          this.menuItemsDocs,
        )
        this.subscribeToContent(
          ContentType.ITEM,
          this.itemsDocs,
          this.items_keyed,
        )
        this.subscribeToContent(
          ContentType.GROUP,
          this.groupsDocs,
          this.groups_keyed,
        )
    */
  }

  subscribeToJsonFileRef() {
    return onSnapshot(
      doc(
        collection(this.firestore, "content"),
        "content",
      ),
      (snapshot) => {
        const documentData = snapshot.data()
        const jsonFileRef = documentData?.["jsonFileRef"]
        const version: string = documentData?.["jsonFileRef_seconds"]?.toString() || ""
        if (jsonFileRef && version !== this.contentService.version()) {
          this.getJsonFile(jsonFileRef, version)
        }
      },
      (error) => {
        console.error("Error getting spreadsheet content doc", error)
      },
    )
  }

  getJsonFile(jsonFileRef: string, version: string) {
    const storageRef = ref(this.storage, jsonFileRef)

    getBlob(storageRef)
      .then(blob => {
        // console.log(blob)
        this.contentService.processJsonFileData(blob, version)
      })

    /*
    this.httpClient.get(
      "https://storage.googleapis.com/rtduggan-2.appspot.com/" + spreadsheetFileName,
      { responseType: "blob" }
    )
      .subscribe(blob => {
        console.log(blob)
      })
    */
  }

  subscribeToSpreadsheetFilename() {
    return onSnapshot(
      doc(
        collection(this.firestore, "content"),
        "content",
      ),
      (snapshot) => {
        const documentData = snapshot.data()
        const spreadsheetRef = documentData?.["spreadsheetRef"]
        if (spreadsheetRef) {
          console.log(spreadsheetRef)
          this.getSpreadsheet(spreadsheetRef)
        }
      },
      (error) => {
        console.error("Error getting spreadsheet content doc", error)
      },
    )
  }

  getSpreadsheet(spreadsheetRef: string) {
    const storageRef = ref(this.storage, spreadsheetRef)

    getBlob(storageRef)
      .then(blob => {
        // console.log(blob)
        this.contentService.processSpreadsheet(blob)
      })

    /*
    this.httpClient.get(
      "https://storage.googleapis.com/rtduggan-2.appspot.com/" + spreadsheetFileName,
      { responseType: "blob" }
    )
      .subscribe(blob => {
        console.log(blob)
      })
    */
  }

  subscribeToContent(
    contentType: ContentType,
    docs: WritableSignal<ContentDoc[]>,
    content_signal?: WritableSignal<Groups | Items>,
  ) {
    const contentQuery = query(
      collection(this.firestore, "content"),
      where("type", "==", contentType),
    )

    return onSnapshot(
      contentQuery,
      (snapshot) => {
        snapshot.docChanges()
          .forEach(change => {
            const changedContentDoc = dataTransform(change.doc.data() as ContentDoc)
            if (change.type === "removed") {
              docs.update(docs => docs
                .filter(doc => doc.id !== changedContentDoc.id))
              /**
               * remove content with content.docId matching changedContentDoc.id
               */
              if (contentType !== ContentType.MENU) {
                content_signal?.update(content_keyed => Object.fromEntries(Object.entries(content_keyed)
                  .filter(([key, content]) => content.docId !== changedContentDoc.id)))
              }
            } else {
              docs.update(docs => {
                const doc_map = new Map(docs.map(doc => [doc.id, doc]))
                doc_map.set(changedContentDoc.id, changedContentDoc as ContentDoc)
                return Array.from(doc_map.values())
              })
              if (contentType !== ContentType.MENU) {
                content_signal?.update(signal => {
                  const content_map = new Map(Object.entries(signal as Groups | Items))
                  /**
                   * flush all content with content.docId matching changedContentDoc.id
                   */
                  Object.entries(signal).forEach(([key, content]) => {
                    if (content.docId === changedContentDoc.id) {
                      content_map.delete(key)
                    }
                  })
                  /**
                   * reload content from changedContentDoc
                   * this effectively updates changed content, adds new content, and removes missing content
                   */
                  Object.entries(changedContentDoc.content).forEach(([key, content]) => {
                    content_map.set(key, content)
                  })
                  return Object.fromEntries(content_map.entries())
                })
              }
            }
          })
      },
      (error) => {
        console.error("Error getting " + contentType + " docs:", error)
      },
    )
  }

}
