import { Component, inject, output, signal } from "@angular/core"
import { CheckoutService } from "./checkout.service"
import { UiIconComponent } from "ui/icon"
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons"

@Component({
  imports: [
    UiIconComponent
  ],
  standalone: true,
  template: `

    <!--login or checkout as quest-->

    <button
      class="btn btn-success btn-sm"
      [disabled]="!checkoutLineItems().length"
      (click)="showCart.emit()"
    >
      <lib-ui-icon [icon]="faCartShopping"/>
    </button>
  `,
  selector: "e2e-cart-icon",
})
export class CartIconComponent {
  private cartService = inject(CheckoutService)

  checkoutLineItems = this.cartService.checkoutLineItems

  showCart = output()

  protected readonly faCartShopping = faCartShopping
}
