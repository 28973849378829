import { Component, computed, inject, input, signal } from "@angular/core"
import { faArrowRotateRight, faArrowsRotate } from "@fortawesome/pro-duotone-svg-icons"
import { map, of, startWith, Subject, switchMap } from "rxjs"
import { filter, tap } from "rxjs/operators"
import { AsyncPipe } from "@angular/common"
import { UiIconComponent } from "ui/icon"
import { DataAccessFirebaseAuthService } from "./data-access-firebase-auth.service"
import { Role } from "./data-access-firebase-auth-state.model"

@Component({
  imports: [
    AsyncPipe,
    UiIconComponent,
  ],
  standalone: true,
  template: `
    @if (customClaimsRoles$ | async; as customClaimsRoles) {
      <div class="row" style="justify-content: space-between">
        <div
          class="column"
          style="width: min(100%, 300px)"
        >
          <button
            class="btn btn-sm"
            [class]="customClaimsRoles.has(Role.BLOCKED) ? 'btn-accent' : 'btn-outline'"
            (click)="change(Role.BLOCKED, customClaimsRoles)"
          >
            Disabled / Blocked
          </button>
          <button
            class="btn btn-sm"
            [class]="customClaimsRoles.has(Role.ADMIN) ? 'btn-accent' : 'btn-outline'"
            (click)="change(Role.ADMIN, customClaimsRoles)"
          >
            Site Administrator
          </button>
          <button
            class="btn btn-sm"
            [class]="customClaimsRoles.has(Role.USER_ADMIN) ? 'btn-accent' : 'btn-outline'"
            (click)="change(Role.USER_ADMIN, customClaimsRoles)"
          >
            User Admin
          </button>
        </div>
        <button
          class="btn btn-circle"
          (click)="refreshUserData()"
          style="font-size: 1.5rem"
        >
          <lib-ui-icon [icon]="refreshAnimation() ? faArrowsRotate : faArrowRotateRight" [animation]="refreshAnimation()"/>
        </button>
      </div>
    }
  `,
  selector: "lib-user-manager-edit",
})
export class DataAccessFirebaseAuthUserManagerEditComponent {
  private authDataAccessService = inject(DataAccessFirebaseAuthService)

  userId = input.required<string>()

  loggedInUserId = this.authDataAccessService.userId

  showRefreshAnimation = signal(false)
  refreshAnimation = computed(() => this.showRefreshAnimation() ? "spin" : undefined)

  reload$ = new Subject<void>()
  customClaimsRoles$ = this.reload$.pipe(
    startWith(null),
    switchMap(() => of(this.userId())),
    filter(Boolean),
    tap(() => this.showRefreshAnimation.set(true)),
    switchMap((userId) => this.authDataAccessService.getUserRecord_FirebaseAuth(userId)),
    map((userRecord) => new Set(userRecord.customClaims?.roles || [])),
    tap(() => {
      setTimeout(() => {
        this.showRefreshAnimation.set(false)
      }, 2000)
    }),
  )

  constructor() {
    // this.saveUserRoles(new Set([Role.ADMIN]))
  }

  refreshUserData() {
    this.reload$.next()
  }

  change(role: Role, customClaimsRoles: Set<Role>) {
    /**
     * Prevent admin from removing their own admin role
     * Prevent admin from blocking themselves.
     *
     * This means that if the current user has Admin role they can't change their own permissions,
     * they already have all permissions,
     * and if they were to remove any permissions, they would also be removing their own Admin role
     */
    if (this.loggedInUserId() && this.loggedInUserId() === this.userId() && customClaimsRoles.has(Role.ADMIN)) return

    switch (role) {
      case Role.BLOCKED:
        this.saveUserRoles(this.changeBlocked(customClaimsRoles))
        break
      case Role.ADMIN:
        this.saveUserRoles(this.changeAdmin(customClaimsRoles))
        break
      case Role.USER_ADMIN:
        this.saveUserRoles(this.changeUserAdmin(customClaimsRoles))
        break
    }
  }

  saveUserRoles(userRoles: Set<Role>) {
    this.authDataAccessService.edit_FirebaseAuth(Array.from(userRoles), this.userId())
    setTimeout(() => {
      this.refreshUserData()
    }, 1000)
  }

  changeBlocked(customClaimsRoles: Set<Role>) {
    switch (customClaimsRoles.has(Role.BLOCKED)) {
      case true:
        customClaimsRoles.delete(Role.BLOCKED)
        break
      case false:
        customClaimsRoles.add(Role.BLOCKED)
        customClaimsRoles.delete(Role.ADMIN)
        customClaimsRoles.delete(Role.USER_ADMIN)
    }
    return customClaimsRoles
  }

  changeAdmin(customClaimsRoles: Set<Role>) {
    switch (customClaimsRoles.has(Role.ADMIN)) {
      case true:
        customClaimsRoles.delete(Role.ADMIN)
        break
      case false:
        customClaimsRoles.delete(Role.BLOCKED)
        customClaimsRoles.add(Role.ADMIN)
        customClaimsRoles.add(Role.USER_ADMIN)
    }
    return customClaimsRoles
  }

  changeUserAdmin(customClaimsRoles: Set<Role>) {
    switch (customClaimsRoles.has(Role.USER_ADMIN)) {
      case true:
        customClaimsRoles.delete(Role.ADMIN)
        customClaimsRoles.delete(Role.USER_ADMIN)
        break
      case false:
        customClaimsRoles.delete(Role.BLOCKED)
        customClaimsRoles.add(Role.USER_ADMIN)
    }
    return customClaimsRoles
  }

  protected readonly faArrowsRotate = faArrowsRotate
  protected readonly Role = Role
  protected readonly faArrowRotateRight = faArrowRotateRight
}

/**
 * single user record for currently logged in user (afAuth.user)
 * using firebase-admin in cloud function
 * does not emit changes to userRecord
 */
// userRecord$ = this.afAuth.user.pipe(
//   filter(user => !!user),
//   switchMap(user => this.usersService.getUserRecord_FirebaseAuth(user?.uid)),
// )
/**
 * same data from afAuth.idTokenResult will emit changes (maybe not customClaims changes, need to test)
 */
