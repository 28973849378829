import { Injectable } from '@angular/core';
import { Subject } from "rxjs"
import { Message } from "primeng/api"

@Injectable({
  providedIn: "root"
})
export class ErrorMessageService {
  notificationChange$: Subject<Message> = new Subject<Message>()

  notify(message: Message) {
    this.notificationChange$.next(message)
  }

}
